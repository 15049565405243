.account {

    &__title {

        margin-bottom: 30px;
		border-bottom: #F4F7FA solid 1px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        &-text {

            color: $black;
            font-size: 34px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 0 !important;
            border-bottom: none !important;
        }

        &-logout {

            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            text-decoration: none;
            color: #383E45 !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-left: auto;
            position: relative;
            top: -3px;
            
            &:before {
                content: '';
                display: inline-block;
                width: 24px;
                height: 24px;
                background: url('../img/sprite.svg#sprite-account-logout-view') no-repeat;
                margin-right: 5px;
            }

            &:hover {
                opacity: 0.7;
            }

        }

    }

    &-info {

        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 40px;

        & .account-form__edit-input {

            position: relative;

            &.--mb-10 {
                margin-bottom: 10px;
            }

            & input[type="text"],
            & input[type="password"],
            & input[type="email"] {

                padding: 11px 76px 11px 20px;
                border-radius: 5px;
                background: #F4F7FA;
                color: #9B9B9B;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border: none;
                outline: none;
                width: 100%;

                &::placeholder,
                &:-ms-input-placeholder,
                &::-ms-input-placeholder {
                    color: #222222;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

            }

            & span {

                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $special-brown;
                position: absolute;
                top: 2px;
                right: 2px;
                z-index: 120;
                width: 54px;
                height: 40px;
                border: none;
                outline: none;
                border-radius: 4px;
                cursor: pointer;

                &:before {
                    content: '';
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url('../img/sprite.svg#sprite-input-check-save-view') no-repeat;

                }

                &:hover {
                    opacity: 0.7;
                }

            }

        }

        &__avatar {

            flex: 0 0 146px;

            &-picture {

                border-radius: 10px;
                overflow: hidden;

                & picture,
                & img {
                    display: block;
                    width: 146px;
                    height: 146px;
                }

            }

            &-change {

                margin-top: 6px;

                & .avatar-change {

                    &__form {

                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        &-label {

                            & .form-label {                                        

                                &__text {
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 125%;
                                    color: #9B9B9B;
                                    position: relative;
                                    top: 1px;
                                    text-decoration: none;
                                    cursor: pointer;
                                    text-align: center;
                                }

                            }

                            &:hover {

                                & .form-label {                             

                                    &__text {
                                        opacity: 0.7;
                                    }

                                }

                            }       

                        }                                

                        &-info {
                            display: block;
                            width: 16px;
                            height: 16px;
                            margin-left: 8px;
                            background: url('../img/sprite.svg#sprite-info-tooltip-view') no-repeat;
                            position: relative;
                            top: 1px;
                        }

                        &-error {
                            display: none;
                            width: 16px;
                            height: 16px;
                            margin-left: 8px;
                            background: url('../img/sprite.svg#sprite-error-tooltip-view') no-repeat;
                            position: relative;
                            top: 1px;
                        }                    

                        &.--error {

                            & .avatar-change__form-error {
                                display: block;
                            }

                            & .avatar-change__form-info {
                                display: none;
                            }

                        }
                        
                        &.--loading {
                            
                            position: relative;

                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: rgba(255, 255, 255, 0.7);
                                z-index: 110;
                            }

                        }

                    }

                    &__progress-bar {
                        display: none;
                        margin-top: 5px;
                    }

                    &__form.--loading + .avatar-change__progress-bar {
                        display: flex;
                    }

                }

            }

            

            &-delete {

                margin-top: 6px;
                text-align: center;

                & a {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 125%;
                    color: #9B9B9B;
                    text-decoration: none;

                    &:hover {
                        opacity: 0.7;
                    }

                }

            }

        }

        &__name,
        &__email,
        &__password {

            padding-top: 40px;
            margin-right: 30px;

            &-title {
                color: #9B9B9B;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 125%;
                margin-bottom: 10px;
            }

            &-text {

                & span.name {
                    display: inline-block;
                    color: #383E45;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    margin-right: 6px;
                }

                & span.edit {

                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    background: url('../img/sprite.svg#sprite-edit-field-pencil-view') no-repeat;
                    cursor: pointer;
                    position: relative;
                    top: 1px;

                    &:hover {
                        opacity: 0.7;
                    }

                }

            }

        }

        &__name {
            max-width: 290px;
            padding-left: 30px;
        }

        &__email {
            flex: 1;
            padding-left: 0px;
        }

        &__password {
            flex: 1;
            padding-left: 0px;
            margin-right: 0;         
        }

    }

    &-favorites {

        &__title {    
            margin-bottom: 40px;
            border-bottom: #F4F7FA solid 1px;
            padding-bottom: 10px;
            color: $black;
            font-size: 34px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        &__empty {
            color: #383E45;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height:100%;
        }

        &__filter {

            margin-bottom: 20px;

            &-form {

                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;

                & .filter-form {

                    &__search {

                        position: relative;
                        flex: 1 1 445px;
                        margin-right: 40px;
            
                        & input[type="text"] {
            
                            width: 100%;
                            border-radius: 5px;
                            background: $light-blue;
                            padding: 11px 76px 11px 20px;
                            color: $black;
                            border: 0;
                            line-height: 1;
                            outline: none;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
            
                            &::-webkit-input-placeholder,
                            &::-moz-placeholder,
                            &::-ms-placeholder,
                            &::placeholder {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                color: #555555;
                            }
            
                        }
            
                        & button {
            
                            position: absolute;
                            top: 2px;
                            right: 2px;
                            z-index: 110;
                            background-color: $special-brown;
                            border: 0;
                            border-radius: 4px;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: center;
                            padding: 8px 15px;
            
                            &:hover {
                                background-color: $ke-button-standart-color-hover;
                            }
            
                            &:before {
                                content: '';
                                display: block;
                                width: 24px;
                                height: 24px;
                                background: url('../img/sprite.svg#sprite-zoom-search-view') no-repeat;
                            }
            
                        }

                    }

                    &__available {
                        flex: 1;
                        margin-right: 40px;
                    }

                    &__sort {
                        flex: 1;
                        margin-left: auto;
                        display: flex;
                        justify-content: flex-end;
                    }

                }

            }

        }

        &__list {

            margin-bottom: 0px;

            & .products-list__item {
                margin-bottom: 40px;
            }

        }

    }

}

@media screen and (max-width: 1439px) {

    .account {

        &-favorites {
    
            &__filter {    
    
                &-form {
    
                    & .filter-form {
    
                        &__search {
                            flex: 1 1 250px;
                        }

                    }

                }

            }

        }

    }

}

@media screen and (max-width: 1169px) {

    .account {

        &-favorites {
    
            &__filter {    
    
                &-form {
    
                    & .filter-form {
    
                        &__search {
                            flex: 1 1 80px;
                        }

                    }

                }

            }

        }

    }

}

@media screen and (max-width: 991px) {

    .account {        

        &-info {

            &__name {
                max-width: inherit;
                margin-right: 0px;
            }

            &__email,
            &__password {
                flex: 0 0 100%;
                width: 100%;
                margin-right: 0px;
            }

        }

        &-favorites {
    
            &__filter {   

                margin-bottom: 60px; 
    
                &-form {
    
                    & .filter-form {
    
                        &__search {
                            flex: 0 0 100%;
                            margin-bottom: 37px;
                        }

                        &__available {
                            margin-right: 20px;
                        }
    
                        &__sort {
                        }

                    }

                }

            }

        }

    }

}

@media screen and (max-width: 767px) {

    .account {
        
    }

}

@media screen and (max-width: 575px) {

    .account {

        &__title {
    
            &-text {
                font-size: 29px !important;
            }

        }

    }

}

@media screen and (max-width: 540px) {

    .account {

        &-info {

            justify-content: center;

            &__name {
                flex: 0 0 100%;
                width: 100%;
                padding-left: 0;
            }

        }

    }

}