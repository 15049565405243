.popup-page {

    & .popup {

        display: block;
        width: 100%;
        height: 100%;
        background-color: #F1F3F9;
    
        .popup__modal {
            display: flex;
            box-shadow: 0 2px 4px rgba(108,111,115,.1);
            border-radius: 25px;
        }

    }

}

@media screen and (max-width: 992px) {

    .popup-page {

    }

}

@media screen and (max-width: 768px) {

    .popup-page {

    }

}

@media screen and (max-width: 576px) {

    .popup-page {

    }

}