.blog {

    &-list {

        display: grid;
        margin-bottom: 58px;
        grid: {
            template-columns: 1fr 1fr;
            gap: 58px 40px;
        }

        &__item {

            position: relative;

            & a {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 110;
            }

            &-picture {

                margin-bottom: 20px;
                border-radius: 10px;
                overflow: hidden;

                & picture,
                & img {
                    width: 100%;
                    height: auto;
                    display: block;
                }

            }

            &-date {
                color: #9B9B9B;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 125%;
                margin-bottom: 15px;
            }

            &-title {
                color: $standart-text-color;
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: 115.385%;
                margin-bottom: 6px;
            }

            &-anounce {
                color: #848484;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 137.5%;
            }

            &:hover .blog-list__item-title {
                opacity: 0.7;
            }

        }

    }
    
    &-article {

        &__picture {

            margin-bottom: 40px;
            border-radius: 10px;
            overflow: hidden;

            & picture,
            & img {
                width: 100%;
                height: auto;
                display: block;
            }

        }

        &__date {
            color: #9B9B9B;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 125%;
            margin-bottom: 40px;
        }

        &__anounce {
            color: #848484;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 137.5%;
        }

        &__separator {
            width: 210px;
            height: 6px;
            background-color: $special-brown;
            margin: 40px 0;
        }

        &__content {

            & p {

                color: $standart-text-color;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 40px;

                &:last-child {
                    margin-bottom: 0;
                }

            }

        }

    }

}

@media screen and (max-width: 1439px) {

    .blog {
    
        &-article {
            
        }

    }

}

@media screen and (max-width: 1169px) {

    .blog {
    
        &-article {
            
        }

    }

}

@media screen and (max-width: 991px) {

    .blog {
    
        &-article {
            
        }

    }

}

@media screen and (max-width: 767px) {

    .blog {

        &-list {
    
            grid: {
                template-columns: 1fr;
                gap: 58px 0px;
            }
    
        }
    
        &-article {
            
        }
        
    }

}

@media screen and (max-width: 575px) {

    .blog {
    
        &-article {
            
        }

    }

}