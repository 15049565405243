.author {

    &-container {

        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 50px;

        &__picture {

            flex: 0 0 524px;
            border-radius: 10px;
            overflow: hidden;

            & picture,
            & img {
                display: block;
                width: 100%;
                height: auto;
            }

        }

        &__content {

            flex: 1;
            padding-left: 36px;

            &-title {
                padding-bottom: 0 !important;
                border: none !important;
                color: $standart-text-color-2 !important;
                font-size: 26px !important;
                font-style: normal !important;
                font-weight: 700 !important;
                line-height: 153.846% !important;
                margin-bottom: 20px !important;
            }

            & > p {

                color: $standart-text-color-2;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 40px;
                }

            }

            &-properties {

                max-width: 600px;
                margin-top: 40px;

                & .content-properties {                    

                    &__item {

                        display: flex;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        justify-content: flex-start;
                        margin-bottom: 45px;

                        &-name {

                            flex: 0 0 200px;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding-right: 12px;

                            & span {
                                color: $standart-text-color-2;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 125%;
                                flex: 0 0 auto;
                                padding-right: 11px;
                            }

                            &:after {
                                content: '';
                                display: block;
                                height: 0;
                                border-bottom: dashed 1px $standart-text-color-2;
                                flex: 1;
                                position: relative;
                                top: 1px;
                            }

                        }

                        &-value {
                            flex: 1;
                            color: $special-brown-light;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 125%;
                        }

                    }

                }

            }

        }

    }

}

.authors {

    &-search {

        &__form {

            padding: 20px;
            background-color: $light-blue;
            margin-bottom: 30px;
            border-radius: 5px;

            & input {

                width: 100%;
                border-radius: 5px;
                background: $white;
                padding: 11px 20px;
                color: $standart-text-color-2;
                border: 0;
                line-height: 1;
                outline: none;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                &::-webkit-input-placeholder,
                &::-moz-placeholder,
                &::-ms-placeholder,
                &::placeholder {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    color: #787878;
                }

            }

        }

    }

    &-list {

        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;

        &__search {

        }

        &__letter {

            flex: 0 0 33.3%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 20px;

            &:nth-child(1n+3) {
                padding-right: 10px;
            }

            &:nth-child(2n+3) {
                padding-left: 5px;
                padding-right: 5px;
            }

            &:nth-child(3n+3) {
                padding-left: 10px;
            }

            &.--not-found {
                opacity: 0.3;
            }

            &-text {
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: $standart-text-color-2;
                flex: 0 0 10px;
                position: relative;
                top: -7px;
            }

            &-items {

                flex: 1;
                padding-left: 15px;
                display: flex;
                flex-direction: column;

                & .letter-items {

                    &__item {

                        display: inline-block;
                        text-decoration: underline !important;
                        color: $special-brown !important;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 137.5%;
                        margin-bottom: 6px;

                        &:hover {
                            text-decoration: none !important;
                            color: $special-brown-light !important;                            
                        }

                        &.--hidden {
                            display: none;
                        }

                    }

                }

            }

        }

    }

}

@media screen and (max-width: 1439px) {

    .author {

    }

}

@media screen and (max-width: 1169px) {

    .author {

    }

}

@media screen and (max-width: 991px) {

    .author {

        &-container {
    
            &__picture {
    
                flex: 0 0 224px;
    
            }

        }

    }

    .authors {
    
        &-list {
    
            &__letter {
    
                flex: 0 0 50%;
    
                &:nth-child(1n+3),
                &:nth-child(2n+3),
                &:nth-child(3n+3) {
                    padding-right: 0px;
                    padding-left: 0px;
                }
    
                &:nth-child(odd) {
                    padding-right: 10px;
                }
    
                &:nth-child(even) {
                    padding-left: 10px;
                }
    
            }
    
        }
    
    }

}

@media screen and (max-width: 767px) {

    .author {

        &-container {
    
            &__picture {
    
                flex: 1 1 100%;
                margin-bottom: 20px;
    
            }

            &__content {
    
                flex: 1 1 100%;
                padding-left: 0;

            }

        }
        
    }

}

@media screen and (max-width: 575px) {

    .author {

    }

    .authors {
    
        &-list {
    
            &__letter {
    
                flex: 0 0 100%;
    
                &:nth-child(odd) {
                    padding-right: 0px;
                }
    
                &:nth-child(even) {
                    padding-left: 0px;
                }
    
            }
    
        }
    
    }

}