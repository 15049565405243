.randombook {

    &__title {    

        color: $black;
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 40px !important;
        border-bottom: none !important;
    }

    &__content {

        height: 370px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;

        &-loader {
            display: none;
        }

        &-item {
            
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            & .content-item {                

                &__picture {

                    flex: 0 0 252px;
                    max-width: 252px;
                    padding: 11px 16px;
                    background-color: $light-blue;
                    border-radius: 10px;
                    overflow: hidden;

                    & picture,
                    & img {
                        display: block;
                        width: 100%;
                    }

                }

                &__info {
        
                    flex: 1;
                    padding-left: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    justify-content: flex-start;
        
                    &-title {        
                        margin-bottom: 12px;
                        color: $black;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 125%;
                        margin-bottom: 12px;
                        padding-bottom: 0;
                        border-bottom: 0;
                    }
        
                    &-rating {

                        margin-bottom: 34px;
        
                        & .info-rating {
        
                            &__stars {
        
                                display: flex;
                                flex-wrap: wrap;
                                align-items: flex-start;
                                justify-content: flex-start;
                                margin-right: 15px;
        
                                &-item {            
        
                                    width: 33px;
                                    height: 28px;                            
                                    background: url('../img/sprite.svg#sprite-review-star-empty-view') 0 0 no-repeat;
                                    margin-right: 7px;
        
                                    &:last-child {
                                        margin-right: 0;
                                    }
        
                                    &.--half {
                                        background: url('../img/sprite.svg#sprite-review-star-half-view') 0 0 no-repeat;
                                    }
        
                                    &.--full {
                                        background: url('../img/sprite.svg#sprite-review-star-full-view') 0 0 no-repeat;
                                    }
        
                                }
        
                            }
        
                        }
        
                    }
        
                    &-props {
        
                        width: 100%;
                        margin-bottom: 30px;
        
                        & .info-props {
        
                            &__list {
        
                                &-item {
        
                                    display: flex;
                                    flex-wrap: wrap;
                                    align-items: center;
                                    justify-content: flex-start;
                                    margin-bottom: 20px;
        
                                    & .list-item {
        
                                        &__name {
                                            flex: 0 0 120px;
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 700;
                                            line-height: 125%;
                                            color: $special-gray;
                                        }
        
                                        &__value {
        
                                            flex: 1;
                                            padding-left: 30px;
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 700;
                                            line-height: 125%;
                                            color: $special-brown-light;
        
                                            & a {
        
                                                display: inline-block;
                                                padding-left: 30px;
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 700;
                                                line-height: 125%;
                                                color: $special-brown-light !important;
                                                text-decoration: none;
        
        
                                                &:hover {
                                                    color: $special-brown !important;
                                                }
        
                                            }
        
                                        }
        
                                    }
        
                                }
        
                            }
        
                        }
        
                    }

                    &-price {    

                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: flex-start;                    

                        & .info-price {

                            &__price {
                                color: $red;
                                font-size: 38.803px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 90.909%;
                                margin-right: 20px;
                            }

                            &__oldprice {
                                font-size: 28.22px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 125%;
                                color: $special-gray;
                                text-decoration: line-through;
                                margin-right: 40px;
                            }

                            &__more {

                                flex: 1;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 125%;
                                margin-right: 24px;
                                display: block;
                                max-width: 260px;
                                color: $white !important;
                                padding: 19px 20px;
                                text-decoration: none;
                                border-radius: 5px;
                                background: $special-brown-light;
                                border: solid 1px $special-brown-light;
                                text-align: center;
                                margin-right: 13px;

                                &:hover {
                                    background: $special-brown;
                                    border-color: $special-brown;
                                }

                            }

                            &__again {

                                flex: 1;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 125%;
                                margin-right: 24px;
                                display: block;
                                max-width: 260px;
                                color: $standart-text-color-2 !important;
                                border: solid 1px $standart-text-color-2;
                                padding: 19px 20px;
                                text-decoration: none;
                                border-radius: 5px;
                                background: transparent;
                                text-align: center;

                                &:hover {
                                    background: $special-brown;
                                    border-color: $special-brown;
                                    color: $white !important;
                                }

                            }

                        }

                    }
        
                }

            }

        }

        &.--loading {

            align-items: center;
            justify-content: center;

            & .randombook__content-loader {

                display: inline-block;

                & img {
                    display: block;
                    width: 228px;
                    height: 218px;
                }

            }

            & .randombook__content-item {
                display: none;
            }

        }

    }


}

@media screen and (max-width: 1440px) {

    .randombook {

    }

}

@media screen and (max-width: 1170px) {

    .randombook {        

        &__content {

            &-item {
    
                & .content-item {

                    &__info {      

                        &-price {

                            & .info-price {
    
                                &__more,
                                &__again {    
                                    max-width: inherit;
                                    padding: 19px 2px;    
                                }

                            }

                        }

                    }

                }

            }

        }

    }

}

@media screen and (max-width: 992px) {

    .randombook {

        &__content {

            &-item {
    
                & .content-item {

                    &__info {                  
        
                        &-props {          
                            margin-bottom: 15px;
                        }

                        &-price { 

                            & .info-price {

                                &__price,
                                &__oldprice {
                                    margin-bottom: 15px;
                                }
    
                                &__more,
                                &__again {    
                                    flex: 0 0 50%;
                                    margin-right: 0;    
                                }

                                &__more {
                                    flex: 0 0 calc(50% - 15px);
                                    margin-right: 15px;
                                }

                            }

                        }

                    }

                }

            }

        }

    }

}

@media screen and (max-width: 768px) {

    .randombook {

        &__title {    
            margin-bottom: 30px !important;
        }

        &__content {

            height: auto;
    
            &-loader {
                display: none;
            }
    
            &-item {
                
                flex-direction: column;
                width: 100%;
    
                & .content-item {                
    
                    &__picture {
    
                        margin-bottom: 30px;
                        overflow: visible;
    
                        & picture,
                        & img {
                            display: block;
                            width: 100%;
                        }
    
                    }
    
                    &__info {
            
                        width: 100%;
                        padding-left: 0px;

                    }

                }

            }

        }
        
    }

}

@media screen and (max-width: 576px) {

    .randombook {

    }

}