.contacts {

    &__title {

        margin-bottom: 30px;
		border-bottom: $light-blue solid 1px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        &-text {

            color: $black;
            font-size: 34px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 0 !important;
            border-bottom: none !important;
        }

        &-wb {

            margin-left: auto;
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: #222222;            
            position: relative;
            top: -3px;


            & a {

                color: $special-brown;
                text-decoration: underline;

                &:hover {
                    color: $ke-button-standart-color-hover;
                    text-decoration: none;
                }

            }
            
        }

    }

    &__connect {

        width: 100%;
        height: 313px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 70px;

        &-background {

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 110;
            width: 100%;

            & picture,
            & img {
                display: block;
                width: 100%;
            }

        }

        &-logo {

            flex: 0 0 50%;
            position: relative;
            z-index: 120;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        &-info {

            position: relative;
            z-index: 120;
            flex: 0 0 50%;

            & .connect-info {

                &__title {
                    color: $white;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 40px;
                }

                &__text {

                    color: $white;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    max-width: 546px;

                    & > span {

                        display: inline-flex;
                        align-items: center;
                        justify-content: flex-start;
                        position: relative;
                        top: -2px;
                        cursor: pointer;

                        & > span.question {
                            display: inline-block;
                            width: 22px;
                            height: 22px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 14.3px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                            color: #555555;
                            background-color: $white;
                            border-radius: 100%;
                            margin: 0 6px;
                        }

                        & > span.text {
                            display: inline-block;
                            color: #9B9B9B;
                        }

                    }

                }

            }

        }

    }

    &__channels {

        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;

        &-email {

            flex: 0 0 50%;

            & .channels-email {

                &__title {
                    color: #222222;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 25px;
                    max-width: 280px;
                }

                &__mail {

                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 110%;
                    text-decoration: none;
                    color: #222222;

                    &:hover {
                        text-decoration: underline;
                    }

                }

            }

        }

        &-social {

            flex: 0 0 50%;

            & .channels-social {

                &__title {
                    color: #222222;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 25px;
                    max-width: 280px;
                }

                &__list {

                    &-item {

                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        margin-right: 12px;
        
                        &:last-child {
                            margin-right: 0;
                        }
        
                        &.--vk {
        
                            background: url('../img/sprite.svg#sprite-vk-dark-view') 0 0 no-repeat;
        
                            &:hover {                    
                                background: url('../img/sprite.svg#sprite-vk-dark-hover-view') 0 0 no-repeat;
                            }
        
                        }
        
                        &.--ok {
        
                            background: url('../img/sprite.svg#sprite-ok-dark-view') 0 0 no-repeat;
        
                            &:hover {                    
                                background: url('../img/sprite.svg#sprite-ok-dark-hover-view') 0 0 no-repeat;
                            }
        
                        }
        
                        &.--tg {
        
                            background: url('../img/sprite.svg#sprite-tg-dark-view') 0 0 no-repeat;
        
                            &:hover {                    
                                background: url('../img/sprite.svg#sprite-tg-dark-hover-view') 0 0 no-repeat;
                            }
        
                        }
        
                        &.--wa {
        
                            background: url('../img/sprite.svg#sprite-wa-dark-view') 0 0 no-repeat;
        
                            &:hover {                    
                                background: url('../img/sprite.svg#sprite-wa-dark-hover-view') 0 0 no-repeat;
                            }
        
                        }

                    }

                }

            }

        }

    }

}

@media screen and (max-width: 1439px) {

    .contacts {

    }

}

@media screen and (max-width: 1169px) {

    .contacts {

        &__connect {
    
            &-logo {
    
                flex: 0 0 40%;
                padding: 0 30px;

                & picture,
                & img {
                    display: inline-block;
                    max-width: 100%;
                }
    
            }
    
            &-info {    
                flex: 0 0 60%;    
            }
    
        }

    }

}

@media screen and (max-width: 991px) {

    .contacts {        

        &__title {

            width: 100%;
            margin-bottom: 40px;
            border-bottom: none;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            &-text {
                width: 100%;
                margin-bottom: 24px !important;
                padding-bottom: 10px !important;
                border-bottom: #F4F7FA solid 1px !important;
            }

        }
    
        &__connect {

            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            height: auto;
            padding: 44px 21px 30px 22px;
    
            &-logo {    
                flex: 1;
                width: 284px;
                padding: 0;
                margin-bottom: 30px;    
            }
    
            &-info {    
                flex: 0 0 100%;    
            }
    
        }

    }

}

@media screen and (max-width: 767px) {

    .contacts {
    
        &__connect {
    
            &-info {    

                & .connect-info {
    
                    &__text {
    
                        & > span {
    
                            display: flex;
                            top: 0px;
                            margin-top: 26px;
    
                            & > span.question {
                                margin: 0 6px 0 0;
                            }
    
                        }
    
                    }
    
                }  

            }
    
        }        

        &__channels {

            &-email {

                flex: 0 0 100%;
                margin-bottom: 38px;

                & .channels-email {
    
                    &__title {
                        max-width: 100%;
                    }

                }

            }

            &-social {

                flex: 0 0 100%;

                & .channels-social {
    
                    &__title {
                        max-width: 100%;
                    }

                }

            }

        }
        
    }

}

@media screen and (max-width: 575px) {

    .contacts {

        &__connect {
    
            &-background {
    
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 110;
                width: 100%;
    
                & picture,
                & img {
                    min-height: 446px;
                    width: auto;
                }
    
            }

        }

        &__channels {

            &-email {

                flex: 0 0 100%;
                margin-bottom: 38px;

                & .channels-email {
    
                    &__title {
                        max-width: 280px;
                    }

                }

            }

            &-social {

                flex: 0 0 100%;

                & .channels-social {
    
                    &__title {
                        max-width: 280px;
                    }

                }

            }

        }

    }

}