html, body {

	font-family: 'Nunito', sans-serif;
	font-weight: normal;
	font-style: normal;
	font-size: 20px;
	line-height: 150%;
	color: $standart-text-color;
	background-color: $body-bg;

	/*& > .flex-grow-1 {		
		overflow-x: clip;
		padding-top: 80px;
	}*/

}

::selection {
    background: $special-gray;
    color: $white;
}

::-webkit-selection {
    background: $special-gray;
    color: $white;
}

::-ms-selection {
    background: $special-gray;
    color: $white;
}

::-o-selection {
    background: $special-gray;
    color: $white;
}

::-moz-selection {
    background: $special-gray;
    color: $white;
}

.body--popup-show {
	overflow: hidden;
}

.frontend {

	& a {
		color: #B99264;
	}

	& h1, .h1 {
		font-size: 34px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		color: #383E45;
		padding-bottom: 10px;
		margin-bottom: 30px;
		border-bottom: #F4F7FA solid 1px;
	}

	& strong {
		font-weight: 700;
	}

	& p {
		color: $standart-text-color;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		margin-bottom: 20px;
	}

	& .simplebar-track.simplebar-vertical {
		width: 8px;
		border-radius: 4px;
		background: rgba(56, 62, 69, 0.1);
	}

	& .simplebar-scrollbar:before {
		top: 1px;
		bottom: 1px;
		left: 1px;
		right: 1px;
	}
	
	& .simplebar-scrollbar:before {
		background: $standart-text-color;
		border-radius: 3px;
		left: 1px;
		right: 1px;
	}

	& .main {

		padding-top: 50px;
		padding-bottom: 80px;

		ul {	
			padding: 0;
			margin: 0;
			list-style: none;	
		}

	}

	& .main.--with-breadcrumbs {
		padding-top: 22px;
	}

	&.index .main {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	/*&.home .main {
		padding-top: 50px;
	}*/

	& .form {

		position: relative;		

		& > .loader {

			display: none;
			width: 96px;
			height: 96px;
			position: absolute;
			z-index: 235;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			
			& .spinner_V8m1 {
				transform-origin: center;
				animation: spinner_zKoa 2s linear infinite;
			}

			& .spinner_V8m1 circle {
				stroke-linecap: round;
				animation: spinner_YpZS 1.5s ease-in-out infinite;
			}

			@keyframes spinner_zKoa{
				100% {
					transform: rotate(360deg);
				}
			}

			@keyframes spinner_YpZS {

				0% {
					stroke-dasharray: 0 600;
					stroke-dashoffset: 0;
				}
				47.5% {
					stroke-dasharray: 168 600;
					stroke-dashoffset: -64;
				}
				95%, 100% {
					stroke-dasharray: 42 600;
					stroke-dashoffset: -236;
				}

			}

		}

		&.--loading {

			&:before {
				content: '';
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				z-index: 230;
				background-color: rgba(255, 255, 255, 0.7);
			}

			& > .loader {
				display: block;
			}

		}

		&__success-message {

			display: none;
			padding: 20px 20px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 240;
			width: 100%;
			text-align: center;

			& .success-message {

				&__title {
					color: $standart-text-color;
					font-size: 32px;
					font-style: normal;
					font-weight: 700;
					line-height: 125%;
					margin: 0 auto 20px;
					max-width: 450px;
				}

				&__text {
					color: $standart-text-color-2;
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
					max-width: 450px;
					margin: 0 auto;
				}

			}

		}

		&.--success {

			&:before {
				content: '';
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				z-index: 230;
				background-color: rgba(255, 255, 255, 0.9);
			}

			& > .form__success-message {
				display: block;
			}

		}

	}

	& .form-group {

		&__error-message {

			display: none;
			width: 20px;
			height: 20px;
			position: absolute;
			background: url('../img/sprite.svg#sprite-input-field-error-icon-view') no-repeat;
			top: 50%;
			transform: translateY(-50%);
			right: 20px;

			&.--second {
				right: 50px;
			}

			&.--third {
				right: 80px;
			}

			&.--comment {
				top: auto;
				transform: none;
				right: auto;
				left: 0;
				bottom: -26px;
			}

			&.--subscribe {
				margin-top: -9px;
				right: 0px;
			}

		}

		&.--error {

			position: relative;

			& > input[type="text"],
			& > input[type="email"],
			& > input[type="tel"],
			& > input[type="password"],
			& > select,
			& > textarea {
				border-color: $red-light !important;
			}

			& > select ~ .form-group__error-message {				
				right: 40px;
			}

			& .form-group__error-message {
				display: block;
			}

		}

		& .form-check.--error .form-check-label {
			color: $red-light;
		}

		&__info-message {
			
			display: none;
			width: 20px;
			height: 20px;
			position: absolute;
			background: url('../img/sprite.svg#sprite-info-tooltip-view') no-repeat;
			top: 50%;
			transform: translateY(-50%);
			right: 20px;
			opacity: 0.3;

			&.--second {
				right: 50px;
			}

			&.--third {
				right: 80px;
			}

		}

		&.--info {

			position: relative;

			/*& > select ~ .form-group__error-message {				
				right: 40px;
			}*/

			& .form-group__info-message {
				display: block;
			}

		}	

	}

	& .form-control,
	& .form-select {
		font-style: italic;
		line-height: 22px;
	}

	& .form-check {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 0;
		padding-left: 0;
	}

	.form-check .form-check-input {
		float: none;
		margin-left: 0;
	}

	& .form-check-label {
		color: $standart-text-color-2;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		cursor: pointer;
		padding-left: 15px;
	}

	& .form-check-input[type="checkbox"] {		
		flex: 0 0 24px;
		width: 24px;
		height: 24px;
		background: url('../img/sprite.svg#sprite-checkbox-empty-view') 0 0 no-repeat;
		margin-top: 0;
		border-radius: 0;
		border: none;
		cursor: pointer;
	}

	& .form-check-input[type="checkbox"]:hover,
	& .form-check-label:hover > .form-check-input[type="checkbox"] {		
		width: 24px;
		height: 24px;
		background: url('../img/sprite.svg#sprite-checkbox-empty-hover-view') 0 0 no-repeat;
		margin-top: 0;
		border-radius: 0;
		border: none;
		cursor: pointer;
	}

	& .form-check-input:checked[type="checkbox"] {		
		background: url('../img/sprite.svg#sprite-checkbox-checked-view') 0 0 no-repeat;
	}

	&.terms-of-use .main,
	&.privacy-policy .main {

		/*& h1, .h1 {
			font-style: normal;
			font-weight: 700;
			font-size: 32px;
			line-height: 141%;
			margin-bottom: 20px;
			color: $standart-text-color;
		}

		& p,
		& p.heading {
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 133%;
			color: $standart-text-color;
			margin-top: 20px;
			margin-bottom: 20px;
		}

		& p {
			font-weight: 300;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		& ul {

			margin: 0;
			padding: 0 0 0 30px;
			list-style-type: disc;

			& li {
				font-style: normal;
				font-weight: 300;
				font-size: 18px;
				line-height: 133%;
				color: $standart-text-color;
			}

		}*/

	}

	& .tooltip a {

		color: $pale-cornflower-blue;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}

	}

	/*h1, .h1 {
		font-family: 'Hero New';
        font-style: normal;
        font-weight: normal;
		font-size: 54px;
		line-height: 58px;
		margin-bottom: 50px;
		color: $periwinkle;
	}

	h2, .h2 {
		font-family: 'Hero New';
        font-style: normal;
        font-weight: normal;
		font-weight: 400;
		font-size: 40px;
		line-height: 46px;
	}

	& .main {

		padding: 80px 0 100px;

		&.--no-padding {
			padding: 0;
		}

		ul {
	
			padding: 0 0 0 28px;
			margin: 0;
			list-style: none;
	
			li {
	
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 30px;
				margin-bottom: 30px;
				position: relative;
	
				&:last-child {
					margin-bottom: 0;
				}
	
				&:before {
					content: '';
					display: block;
					width: 8px;
					height: 8px;
					background-color: $skyblue;
					position: absolute;
					top: 11px;
					left: -28px;
				}
	
			}
	
			&.digits,
			&.no-marker {
	
				& li {
	
					&:before {
						display: none;
					}
	
				}
	
			}

			&.digits {
	
				& li {

					display: flex;
					flex-wrap: wrap;
					align-items: flex-start;
					justify-content: flex-start;
					margin-bottom: 30px;

					&:last-child {
						margin-bottom: none;
					}

					& span.num {
						display: block;
						width: 29px;
						height: 30px;
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 30px;
						color: $skyblue;
					}

					& span.text {
						flex: 1;
						padding-left: 10px;
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 30px;
					}

				}

			}
	
			&.no-mb {
				margin-bottom: 0 !important;
			}
	
		}

	}*/

	/*&.home .main {

		padding-top: 68px;
		padding-bottom: 68px;

	}*/

	/*.gf-button {

		display: inline-block;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 28px;
		text-align: center;
		text-transform: uppercase;
		padding: 16px 50px;
		background-color: $gf-button-blue-color;
		color: $standart-text-light-color;
		transition: all .25s linear;
		border-radius: 2px;
		outline: none;
		border: none;
		text-decoration: none;

		&:hover {
			background-color: $gf-button-blue-color-hover;			
		}

		&:active {
			background-color: $gf-button-blue-color-active;			
		}

		&[disabled],
		&.--disabled {
			background-color: $gf-button-blue-color-disabled;			
		}

		&.--red {

			background-color: $gf-button-red-color;

			&:hover {
				background-color: $gf-button-red-color-hover;			
			}

			&:active {
				background-color: $gf-button-red-color-active;			
			}

			&[disabled],
			&.--disabled {
				background-color: $gf-button-red-color-disabled;			
			}

		}

		&.--dark-blue {

			background-color: $periwinkle;

			&:hover {
				background-color: lighten($periwinkle, 5%);			
			}

			&:active {
				background-color: lighten($periwinkle, 5%);			
			}

			&[disabled],
			&.--disabled {
				background-color: lighten($periwinkle, 20%);			
			}

		}

	}*/	

}

/*.home.frontend {

	& .main {
		padding-top: 0px;
	}

}*/

body.body--top-modal.--center {

	/*overflow: hidden;

	& .top-modal-overlay {
		display: block;
		height: 100%;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.8);
		position: fixed;
		z-index: 910;
		top: 0;
		left: 0;
		overflow-y: scroll;
	}*/

}

body.--open-catalog-book-modal {
	/*overflow: hidden;*/
}

@media screen and (max-width: 1170px) {

}

@media screen and (max-width: 992px) {	

	.body--nav-show {
		/*overflow: hidden;*/

		& .header {
			min-height: 100vh;
			overflow: visible;
		}

		& .main,
		& .footer {
			display: none;
		}

	}

	/*.body--top-modal {
		overflow: hidden;
	}*/

	.frontend {

		& .main {
			padding-top: 125px;
		}
	
		& .main.--with-breadcrumbs {
			padding-top: 125px;
		}
	
		/*h1, .h1 {
			font-size: 40px;
			line-height: 46px;
		}*/
	
		/*h2, .h2 {
			font-size: 28px;
			line-height: 38px;
		}*/
	
		/*& .main {
			
			padding: 40px 0 80px;

			&.--no-padding {
				padding: 0;
			}

		}

		& .accordion {

			&-button {
				
				&__desc {
					margin-left: 0px;
					margin-top: 8px;
				}
	
			}

			&-body {

				&__text {
					padding-left: 0;
				}

			}

		}*/
	
	}

}

@media screen and (max-width: 768px) {
    
    .frontend {

		&.--popup-menu-show {
			overflow: hidden;
		}

		& .main {
			padding-top: 194px;
		}
	
		& .main.--with-breadcrumbs {
			padding-top: 194px;
		}

		& .form {

			&__success-message {
	
				& .success-message {
	
					&__title {
						max-width: inherit;
					}
	
					&__text {
						max-width: inherit;
					}
	
				}
	
			}

		}

    }

}

@media screen and (max-width: 576px) {
    
    .frontend {

		& .main {
            padding-top: 116px;
			padding-bottom: 60px;
		}
	
		& .main.--with-breadcrumbs {
			padding-top: 116px;
		}

    }

}