.popup {

    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: transparent;
    transition: background .25s linear;
    z-index: 950;

    &__tooltip {

        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 420px;
        background-color: $white;
        padding: 30px 24px 24px 39px;
        border-radius: 4px;

        &:before {
            content: '';
            display: block;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 21px 32px 21px 0;
            border-color: transparent $white transparent transparent;
            position: absolute;
            top: 20px;
            left: -20px;
            z-index: 110;
        }

        &.--arrow-right:before {
            left: auto;
            right: -20px;
            transform: rotate(180deg);
            transform-origin: center center;
        }

        &-close {

            display: block;
            position: absolute;
            z-index: 100;
            width: 24px;
            height: 24px;
            right: 15px;
            top: 15px;
            background: url('../img/sprite.svg#sprite-modal-close-view') no-repeat;
            background-size: 100% 100%;
            transition: opacity .25s linear;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }

        }

        &-body {

            & .tooltip-body {

                &__title {
                    font-size: 19.5px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    color: #555555;
                    margin-bottom: 30px;
                    max-width: 318px;
                }

                &__text {
                    color: #555555;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }

            }

        }


    }

    &__modal {

        display: none;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 490px;
        min-height: 518px;
        background: rgb(56,62,69);
        background: -moz-linear-gradient(90deg, rgba(56,62,69,1) 50%, rgba(56,62,69,0) 50%);
        background: -webkit-linear-gradient(90deg, rgba(56,62,69,1) 50%, rgba(56,62,69,0) 50%);
        background: linear-gradient(90deg, rgba(56,62,69,1) 50%, rgba(56,62,69,0) 50%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#383e45",endColorstr="#383e45",GradientType=1);
        padding-left: 80px;
        border-radius: 25px;

        &.--remove-favorite-book {
            height: 408px;
        }

        &.--add-book-review {
            width: 1031px;
        }

        &:after {
            content: '';
            display: block;
            width: 58px;
            height: 66px;
            position: absolute;
            bottom: -66px;
            right: 80px;
            z-index: 110;
            background: url('../img/sprite.svg#sprite-modal-bookmark-view') 0 0 no-repeat;
            background-size: 100% 100%;
        }

        &-wrap {

            background-color: $white;
            border-radius: 25px;            
            width: 100%;

            &.--loading {
            }

            & > .loader {

                display: none;
                width: 96px;
                height: 96px;
                position: absolute;
                z-index: 235;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                
                & .spinner_V8m1 {
                    transform-origin: center;
                    animation: spinner_zKoa 2s linear infinite;
                }
    
                & .spinner_V8m1 circle {
                    stroke-linecap: round;
                    animation: spinner_YpZS 1.5s ease-in-out infinite;
                }
    
                @keyframes spinner_zKoa{
                    100% {
                        transform: rotate(360deg);
                    }
                }
    
                @keyframes spinner_YpZS {
    
                    0% {
                        stroke-dasharray: 0 600;
                        stroke-dashoffset: 0;
                    }
                    47.5% {
                        stroke-dasharray: 168 600;
                        stroke-dashoffset: -64;
                    }
                    95%, 100% {
                        stroke-dasharray: 42 600;
                        stroke-dashoffset: -236;
                    }
    
                }
    
            }
    
            &.--loading {

                position: relative;
    
                &:before {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 230;
                    background-color: rgba(255, 255, 255, 0.7);
                    border-radius: 25px; 
                }
    
                & > .loader {
                    display: block;
                }
    
            }

        }

        &-header {

            position: relative;
            width: 100%;
            padding-left: 40px;
            padding-top: 17px;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: $gray;

            & a {
                
                text-decoration: none;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                color: $gray;
                transition: opacity .25s linear;

                &:hover {
                    opacity: 0.7;
                }

            }

            & .modal-header {

                &__close {

                    display: block;
                    position: absolute;
                    z-index: 100;
                    width: 24px;
                    height: 24px;
                    right: 18px;
                    background: url('../img/sprite.svg#sprite-modal-close-view') no-repeat;
                    background-size: 100% 100%;
                    transition: opacity .25s linear;
                    cursor: pointer;
        
                    &:hover {
                        opacity: 0.7;
                    }

                }

            }

        }

        &.--add-book-review .popup__modal-header {

            padding-top: 29px;

            & .modal-header {

                &__close {
                    top: 12px;
                }

            }

        }
        
        &-body {

            /*padding-left: 71px;
            padding-right: 66px;*/
            padding-left: 54px;
            padding-right: 54px;
            width: 100%;

            & .modal-body {

                &__title {

                    font-style: normal;
                    font-weight: 700;
                    font-size: 34px;
                    line-height: normal;
                    margin-bottom: 30px;
                    text-align: center;

                    &.--forgot {
                        font-size: 25px;
                    }

                    &.--change-pwd {
                        font-size: 32px;
                    }

                    &.--medium {
                        font-size: 27px;
                    }

                    &.--small {
                        font-size: 20px;
                        margin-bottom: 28px;
                    }

                    &.--email {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: flex-start;
                        position: relative;
                    }

                    & .not-confirmed,
                    & .confirmed {
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        background: url('../img/sprite.svg#sprite-icon-email-cross-view') no-repeat;
                        margin: 0 6px;
                    }

                    & .confirmed {
                        background: url('../img/sprite.svg#sprite-icon-email-check-view') no-repeat;
                        position: relative;
                        top: -1px;
                    }

                    & .send-email-confirmation {

                        font-style: normal;
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 133%;
                        color: $gray;

                        &:hover {                            
                            text-decoration: none;
                        }

                    }

                    & .confirm-send {

                        position: absolute;
                        top: 30px;
                        left: 0;
                        color: $gray-light;
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 133%;

                        &.--error {
                            color: $red;
                        }

                    }

                }

                &__message {

                    margin-bottom: 20px;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 140%;
                    color: $standart-text-color;

                    &.--success {
                        color: $green;
                    }

                    &.--error {
                        color: $red;
                    }

                }

                &__text {

                    & span.comeback-favorite-book {

                        display: inline-block;
                        color: #9B9B9B;
                        font-size: 26px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-decoration-line: underline;
                        cursor: pointer;

                        &:hover {
                            text-decoration: none;
                        }

                    }

                }

                &__services-list {

                    margin-bottom: 30px;

                    & .services-list {

                        &__item {

                            cursor: pointer;
                            margin-bottom: 17px;
                            text-align: center;
                            padding: 16px 18px;
                            border: 2px solid #D6D6D6;
                            border-radius: 10px;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                            position: relative;
                            transition: background-color .25s linear;
                            display: block;
                            text-decoration: none;
                            color: #555555;

                            &:hover {
                                background-color: #D6D6D6;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            & span.icon {
                                position: absolute;
                                top: 50%;
                                left: 18px;
                                transform: translateY(-50%);
                            }

                            &.--mail span.icon {
                                display: inline-block;
                                width: 29px;
                                height: 23px;
                                background: url('../img/sprite.svg#sprite-login-mail-view') no-repeat;
                                background-size: 100% 100%;
                            }

                            &.--google span.icon,
                            &.--GoogleOAuth span.icon {
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background: url('../img/sprite.svg#sprite-login-google-view') no-repeat;
                                background-size: 100% 100%;
                            }

                            &.--vkontakte span.icon,
                            &.--VKontakte span.icon {
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background: url('../img/sprite.svg#sprite-login-vkontakte-view') no-repeat;
                                background-size: 100% 100%;
                            }

                        }

                    }

                }

                &__login {

                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 28px;
                    text-align: center;

                    & a {

                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 28px;                        
                        text-decoration: none !important;
                        color: #9B9B9B !important;
                        transition: opacity .25s linear;

                        &:hover {
                            color: $special-brown !important;
                        }

                    }

                    &.--large {

                        & a {
                            font-style: normal;
                            font-size: 26px;
                            font-weight: 700;
                            line-height: normal;
                        }

                    }

                }

                &__privacy-policy {

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    color: #9B9B9B;
                    margin-top: 30px;

                    & a {

                        color: $special-brown-light !important;
                        text-decoration: underline !important;

                        &:hover {                        
                            color: $special-brown !important;
                            text-decoration: none !important;
                        }

                    }

                }

                &__rating {

                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 35px;

                    &-star {

                        width: 38px;
                        height: 30px;
                        padding-right: 5px;
                        cursor: pointer;

                        &:last-child {
                            padding-right: 0;
                        }

                        & span {
                            display: block;
                            width: 33px;
                            height: 30px;
                            background: url('../img/sprite.svg#sprite-interaction-review-star-empty-view') 0 0 no-repeat;
                            background-size: 100% 100%;
                        }

                        &.--active span,
                        &.--selected span {
                            background: url('../img/sprite.svg#sprite-interaction-review-star-full-view') 0 0 no-repeat;
                            background-size: 100% 100%;
                        }

                    }

                    &.--hover {

                        & .modal-body__rating-star.--selected span {
                            background: url('../img/sprite.svg#sprite-interaction-review-star-empty-view') 0 0 no-repeat;
                            background-size: 100% 100%;
                        }

                        & .modal-body__rating-star.--active span {
                            background: url('../img/sprite.svg#sprite-interaction-review-star-full-view') 0 0 no-repeat;
                            background-size: 100% 100%;
                        }

                    }

                }

                &__signup-form,
                &__signin-form,
                &__basic-form {

                    margin-bottom: 30px;

                    & .signup-form,
                    & .signin-form,
                    & .basic-form {

                        &__input {

                            margin-bottom: 17px;

                            &:last-child {
                                margin-bottom: 37px;
                            }

                            & input {

                                padding: 15px 20px;
                                border-radius: 5px;
                                background: #F4F7FA;
                                color: #222222;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                border: solid 1px transparent;
                                outline: none;
                                width: 100%;
                
                                &::placeholder,
                                &::-webkit-input-placeholder,
                                &:-ms-input-placeholder,
                                &::-ms-input-placeholder {
                                    color: #9B9B9B;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                }

                                /*border: 1px solid $pale-cornflower-blue;
                                border-radius: 15px;
                                outline: none;
                                font-style: italic;
                                font-weight: 300;
                                font-size: 16px;
                                line-height: 22px;
                                color: $gray;
                                padding: 18px 20px;
                                width: 100%;*/  

                            }

                            &.--icon {

                                position: relative;

                                & > span.icon {

                                    position: absolute;
                                    top: 50%;
                                    right: 20px;
                                    transform: translateY(-50%);
                                    display: block;

                                    &.--eye-hide {
                                        width: 24px;
                                        height: 18px;
                                        background: url('../img/sprite.svg#sprite-eye-hide-view') no-repeat;
                                        background-size: 100% 100%;
                                        cursor: pointer;
                                    }

                                    &.--eye-show {
                                        width: 24px;
                                        height: 13px;
                                        background: url('../img/sprite.svg#sprite-eye-show-view') no-repeat;
                                        background-size: 100% 100%;
                                        cursor: pointer;
                                    }

                                    &.--check {
                                        width: 15px;
                                        height: 9px;
                                        background: url('../img/sprite.svg#sprite-form-filed-check-view') no-repeat;
                                        background-size: 100% 100%;
                                        cursor: pointer;
                                    }

                                }

                                & input {
                                    padding: 18px 64px 18px 20px;                                    
                                }

                            }

                            &-label {
                                color: $standart-text-color-2;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 200%;
                                margin-bottom: 8px;

                            }

                            & textarea {

                                border-radius: 14px;
                                border: 1px solid $standart-text-color-2;
                                background: $light-blue;
                                color: $standart-text-color-2;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: 125%;
                                height: 185px;
                                width: 100%;
                                padding: 20px;
                                resize: none;
                                outline: none;
                
                                &::placeholder,
                                &::-webkit-input-placeholder,
                                &:-ms-input-placeholder,
                                &::-ms-input-placeholder {
                                    color: $standart-text-color-2;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 300;
                                    line-height: 125%;
                                }

                            }

                        }

                        &__submit {

                            text-align: center;
        
                            & > button {
        
                                display: inline-block;
                                text-decoration: none;
                                transition: opacity .25s linear;
                                outline: none;
                                border: none;
                                background: none;
                                font-style: normal;
                                font-size: 26px;
                                font-weight: 700;
                                line-height: normal;
                                text-decoration: none !important;
                                color: #9B9B9B !important;
                                transition: opacity .25s linear;
        
                                &:hover {
                                    color: $special-brown !important;
                                }
        
                            }
        
                            & > button.--classic {

                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 125%;
                                color: $white !important;
                                padding: 12px 20px;
                                width: 216px;
                                background-color: $special-brown-light;
                                border-radius: 5px;
        
                                &:hover {
                                    color: $white !important;
                                    background-color: $special-brown;
                                }
        
                            }
        
                        }

                    }

                }
            
            }

        }
        
        &.--remove-favorite-book .popup__modal-body {
            padding-left: 29px;
            padding-right: 29px;
        }
        
        &.--add-book-review .popup__modal-body {

            padding-left: 38px;
            padding-right: 28px;

            & .modal-body {

                &__title {
                    margin-bottom: 20px;         
                }

            }

        }

        &-footer {

            /*padding-left: 40px;*/
            
            padding-left: 54px;
            padding-right: 54px;
            padding-bottom: 17px;

            width: 100%;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 24px;

            & a {

                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 24px;
                text-decoration: underline;
                color: $special-brown-light;

                &:hover {
                    text-decoration: none;
                }

            }

        }

    }
    
    .frontend.--popup-show & {

        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(47, 47, 47, 0.3);

        &__modal,
        &__tooltip {

            &.--active {
                display: flex;
            }

        }

    }

}

@media screen and (max-width: 1169px) {

    .popup {

        &__modal {
    
            &.--add-book-review {
                width: 700px;
            }

        }

    }

}

@media screen and (max-width: 991px) {

    .popup {

    }

}

@media screen and (max-width: 767px) {

    .popup {

        &__modal {
    
            padding-left: 0;
            max-width: 420px;
    
            &.--add-book-review {
                width: 420px;
            }

            &-body {
                padding-left: 34px;
                padding-right: 34px;
            }

        }

    }

}

@media screen and (max-width: 700px) {

    /*.popup {

        &__modal {
    
            top: auto;
            left: auto;
            transform: none;  
            width: 100%;
            height: 100vh;
            min-height: 600px;
            overflow-y: scroll;
    
            &-left {
                display: none;
            }
    
            &-right {
                flex: 0 0 100%;
            }

            &-close {
                width: 32px;
                height: 32px;
                top: 26px;
                right: 22px;
                background: url('../img/sprite.svg#sprite-header-menu-close-mobile-view') no-repeat;    
            }

            &-body {

                padding-left: 25px;
                padding-right: 25px;

                & .modal-body {
    
                    &__title {
                        text-align: center;
                    }

                    &__login {
                        text-align: center;                        
                    }

                    &__services-list {

                        margin-bottom: 30px;
    
                        & .services-list {
    
                            &__item {
    
                                & span.text {
                                    position: relative;
                                    top: 1px
                                }

                            }

                        }

                    }                    

                    &__signup-form,
                    &__signin-form,
                    &__basic-form {

                        & .signup-form,
                        & .signin-form,
                        & .basic-form {

                            &__submit {
            
                                & > button {

                                    margin:  0 auto;
        
                                    & > span.text {
                                        font-size: 18px;
                                        line-height: 21px;
                                    }

                                }

                            }

                        }

                    }

                }

            }

            &-header {

                padding-left: 25px;

                & a {
                    position: relative;
                    top: 28px;
                }
            }

            &-footer {
                padding-left: 25px;
                padding-right: 25px;
                text-align: center;
            }

        }

        .frontend.--popup-show & {    
            height: 100vh;
            background-color: $white;
        }

    }*/

}

@media screen and (max-width: 575px) {

    .popup {

        &__modal {

            max-width: 340px;
    
            &.--add-book-review {
                width: 340px;
            }

        }

    }

}