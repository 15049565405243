.frontend {

    .breadcrumbs {

        margin-bottom: 16px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        &:before {                    
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            background: url('../img/sprite.svg#sprite-home-main-view') 0 0 no-repeat;
            background-size: 100% 100%;
            margin-right: 4px;
        }

        &__list {

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            list-style: none;
            padding: 0;
            margin: 0;

            &-item {

                margin: 0 8px 0 0;
                padding: 0;

                &:after {
                    content: '/';
                    display: inline-block;
                    color: #555555;
                    opacity: 0.4;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 114.286%;
                    position: relative;
                    right: -2px;
                }

                &:first-child {

                }

                &:last-child {

                    margin-right: 0;

                    &:after {
                        display: none;
                    }

                }

                & a {

                    display: inline-block;
                    color: #383E45;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 114.286%;
                    text-decoration: none;

                    &:hover {
                        opacity: 0.7;
                    }

                }

                & span {
                    display: inline-block;
                    color: #9B9B9B;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 114.286%;
                    text-decoration: none;
                }

            }

        }

    }

    .swiper-arrows-nav {

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        &.--mobile {
            margin-top: 36px;
            justify-content: center;
        }

        &__prev,
        &__next {

            display: inline-block;
            width: 50px;
            height: 50px;
            background: url('../img/sprite.svg#sprite-swiper-arrow-view') no-repeat;
            background-size: 100% 100%;
            margin-right: 10px;
            cursor: pointer;

            &:hover {
                background: url('../img/sprite.svg#sprite-swiper-arrow-hover-view') no-repeat;    
                background-size: 100% 100%;            
            }

        }

        &__next {
            margin-right: 0px;
            transform: rotate(180deg);
            transform-origin: center center;
        }

        &__prev.swiper-button-disabled,
        &__prev.swiper-button-disabled:hover {
            opacity: 0.3;
            cursor: default;
            background: url('../img/sprite.svg#sprite-swiper-arrow-view') no-repeat;
            background-size: 100% 100%;
        }

        &__next.swiper-button-disabled,
        &__next.swiper-button-disabled:hover {
            background: url('../img/sprite.svg#sprite-swiper-arrow-view') no-repeat;   
            background-size: 100% 100%;
            transform: rotate(180deg);
            transform-origin: center center;
            opacity: 0.3;
            cursor: default;
        }

    }

    .swiper {

        height: 408px;
        overflow: visible;
        overflow-x: clip;
        z-index: 210;

        &.products-swiper {
            margin-bottom: 65px;
            margin: 0 0.75rem;
        }

        &.authors-swiper {
            height: 374px;
            margin: 0 0.75rem;
        }
        
    }

    .products-list {

        /*display: grid;
        grid: {
            template-columns: repeat(5, 1fr);
            template-rows: auto;
            gap: 0px 37px;
        }*/
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        /*justify-content: space-between;*/
        justify-content: flex-start;
        margin-bottom: 45px;
        min-height: 448px;

        &.--col-4 {
            justify-content: flex-start;
        }

        &.--col-5 {
            justify-content: flex-start;
        }

        &.swiper-wrapper {
            flex-wrap: nowrap;
            margin-bottom: 0px;
        }

        &__item {

            flex: 0 0 252px;
            max-width: 252px;
            border-radius: 10px;
            display: block;
            position: relative;

            &-wrap {
                border-radius: 10px;
            }

            &-detail {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 200;
            }

            &-delete {

                width: 36px;
                height: 36px;
                background-color: $special-brown-light;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                z-index: 210;
                top: 22px;
                right: 27px;
                border-radius: 5px;
                cursor: pointer;

                &:before {
                    content: '';
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url('../img/sprite.svg#sprite-trash-view') 0 0 no-repeat;
                    background-size: 100% 100%;
                }

                &:hover {
                    background-color: $special-brown;
                }

            }

            &-picture {

                padding: 11px 16px;
                background-color: $light-blue;
                margin-bottom: 15px;
                border-radius: 10px;

                & .item-picture {

                    &__wrap {

                        border-radius: 10px;
                        position: relative;
                        overflow: hidden;
                        height: 291px;
                        display: block;

                        & picture,
                        & img {
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 100;
                        }

                    }

                }

            }

            &-content {

                padding: 0px 16px;

                & .item-content {

                    &__price {

                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom: 10px;

                        & span {
                            display: inline-block;
                            color: $red;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 90.909%;
                        }

                        & span.old {
                            display: inline-block;
                            color: #9B9B9B;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 125%;
                            text-decoration: line-through;
                            margin-left: 12px;
                        }

                    }

                    &__author {
                        color: #9B9B9B;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 125%;
                        margin-bottom: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;                                
                        white-space: nowrap;
                    }

                    &__name {
                        color: $black;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 125%;
                        overflow: hidden;
                        text-overflow: ellipsis;                                
                        white-space: nowrap;
                    }

                    &__detail {

                        display: none;
                        margin-top: 15px;
                        padding: 11px 0px;
                        border-radius: 5px;
                        color: $white;
                        text-decoration: none;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        background-color: $ke-button-standart-color;
                        transition: background-color .25s linear;
                        text-align: center;
                        width: 100%;

                        &:hover {
                            background-color: $ke-button-standart-color-hover;
                            color: $white;
                        }

                        &:active,
                        &:focus {
                            background-color: $ke-button-standart-color-active;
                            color: $white;
                        }

                    }

                }

            }

        }

        &__item:hover {
            
            position: relative;

            .products-list__item-wrap {
                background-color: #F4F7FA;
                position: absolute;
                z-index: 250;
                top: 0;
                left: 0;
                width: 100%;
            }

            .products-list__item-detail {
                display: block;
            }

            & .products-list__item-content {

                padding: 0px 16px 11px;                       

                & .item-content {

                    &__author,
                    &__name {
                        overflow: visible;
                        text-overflow: inherit;                                
                        white-space: normal;
                    }

                    &__detail {
                        display: block;
                    }

                }

            }

        } 

        &.--col-4 .products-list__item {

            flex: 0 0 252px;
            max-width: 252px;
            border-radius: 10px;
            display: block;
            position: relative;

            &:not(.swiper-slide) {
                margin-right: 37px;
            }

            &:not(.swiper-slide):nth-child(4n+4) {
                margin-right: 0;
            }

        }       

        &.--col-5 .products-list__item {

            flex: 0 0 252px;
            max-width: 252px;
            border-radius: 10px;
            display: block;
            position: relative;

            &:not(.swiper-slide) {
                margin-right: 37px;
            }

            &:not(.swiper-slide):nth-child(5n+5) {
                margin-right: 0;
            }

        }

    }

    .authors-list {

        /*display: grid;
        grid: {
            template-columns: repeat(5, 1fr);
            template-rows: auto;
            gap: 0px 37px;
        }*/
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        margin-bottom: 65px;

        &.swiper-wrapper {
            flex-wrap: nowrap;
            margin-bottom: 0px;
        }

        &__item {

            flex: 0 0 252px;
            max-width: 252px;
            border-radius: 10px;
            display: block;

            &:not(.swiper-slide) {
                margin-right: 37px;
            }

            &:not(.swiper-slide):nth-child(5n+5) {
                margin-right: 0;
            }
            

            &-wrap {

                border-radius: 10px;
                position: relative;

                & > a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 110;
                }

            }

            &-picture {

                padding: 11px 16px;
                background-color: #F4F7FA;
                margin-bottom: 15px;
                border-radius: 10px;

                & .item-picture {

                    &__wrap {

                        border-radius: 10px;
                        position: relative;
                        overflow: hidden;
                        height: 291px;
                        display: block;

                        & picture,
                        & img {
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 100;
                        }

                    }

                }

            }

            &-content {

                padding: 0px 16px;

                & .item-content {

                    &__name {
                        color: $black;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 125%;
                        margin-bottom: 5px;
                        overflow: hidden;
                        text-overflow: ellipsis;                                
                        white-space: nowrap;
                    }

                    &__genres {
                        color: #9B9B9B;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 125%;
                        overflow: hidden;
                        text-overflow: ellipsis;                                
                        white-space: nowrap;
                    }

                    &__detail {

                        display: none;
                        margin-top: 15px;
                        padding: 11px 0px;
                        border-radius: 5px;
                        color: $white;
                        text-decoration: none;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        background-color: $ke-button-standart-color;
                        transition: background-color .25s linear;
                        text-align: center;
                        width: 100%;
                        cursor: pointer;

                        &:hover {
                            background-color: $ke-button-standart-color-hover;
                            color: $white;
                        }

                        &:active,
                        &:focus {
                            background-color: $ke-button-standart-color-active;
                            color: $white;
                        }

                    }

                }

            }

        }

        &__item:hover {
            
            position: relative;

            .authors-list__item-wrap {
                background-color: #F4F7FA;
                position: absolute;
                z-index: 250;
                top: 0;
                left: 0;
                width: 100%;
            }

            & .authors-list__item-content {

                padding: 0px 16px 11px;                       

                & .item-content {

                    &__genres,
                    &__name {
                        overflow: visible;
                        text-overflow: inherit;                                
                        white-space: normal;
                    }

                    &__detail {
                        display: block;
                    }

                }

            }

        }

    }

    & .pagenation {

        /*margin-top: 70px;*/

        &__list {

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;

            &-item {

                display: inline-block;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                text-decoration: none;
                color: #1A212F;
                margin: 0 10px;
                cursor: pointer;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                & span {      
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 28px;                             
                }

                &.--separator {
                    cursor: default;   
                }

                &:not(.--separator):hover {
                    color: $special-brown;
                    cursor: pointer;
                    
                }

                &.--active,
                &.--active:hover {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    background-color: $special-brown;
                    color: $white;
                    cursor: default;
                }

                &.--prev,
                &.--next {

                    width: auto;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    &.--disabled,
                    &.--disabled:hover {
                        opacity: 0.5;
                        cursor: default;
                        color: #1A212F;
                        background-color: transparent;
                    }

                }

                &.--prev {

                    &:before {
                        content: '';
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        background: url('../img/sprite.svg#sprite-pagenation-arrow-left-view') 0 0 no-repeat;
                        background-size: 100% 100%;
                        margin-right: 8px;
                    }

                    &:not(.--disabled):hover {
                        &:before {
                            background: url('../img/sprite.svg#sprite-pagenation-arrow-left-hover-view') 0 0 no-repeat;
                            background-size: 100% 100%;
                        }
                    }

                }

                &.--next {

                    &:after {
                        content: '';
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        background: url('../img/sprite.svg#sprite-pagenation-arrow-right-view') 0 0 no-repeat;
                        background-size: 100% 100%;
                        margin-left: 8px;
                    }

                    &:not(.--disabled):hover {
                        &:after {
                            background: url('../img/sprite.svg#sprite-pagenation-arrow-right-hover-view') 0 0 no-repeat;
                            background-size: 100% 100%;
                        }
                    }

                }

            }

        }

    }

    & .sortable {

        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;

        &__label {
            color: $standart-text-color-2;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-right: 10px;
        }

        &__select {

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            cursor: pointer;
            user-select: none;
            white-space: nowrap;

            &-value {

                &:after {
                    content: '';
                    display: inline-block;
                    margin-left: auto;
                    width: 12px;
                    height: 12px;
                    background: url('../img/sprite.svg#sprite-expand-filter-arrow-view') 0 0 no-repeat;
                    background-size: 100% 100%;
                    position: relative;
                    top: 1px;
                }

                & span {
                    color: $standart-text-color-2;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-right: 3px;
                    position: relative;
                    top: -1px;
                }

            }

            &-list {

                position: absolute;
                width: 200px;
                top: 35px;
                right: 0;
                z-index: 260;
                background-color: $light-blue;
                display: none;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                border-radius: 5px;
                overflow: hidden;

                & .select-list {

                    &__item {

                        width: 100%;

                        & a {

                            display: block;
                            padding: 12px 20px;
                            background-color: transparent;
                            text-decoration: none;
                            color: $standart-text-color-2;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;

                            &:hover {
                                background-color: darken($light-blue, 2.5%);
                            }

                        }

                        &.--active {

                            & a,
                            & a:hover {
                                background-color: darken($light-blue, 5%);
                            }

                        }

                    }

                }

            }

            &.--open .sortable__select-value {

                &:after {
                    transform: rotate(180deg);
                    transform-origin: center center;
                    position: relative;
                    top: 1px;
                }

            }

            &.--open .sortable__select-list {
                display: flex;
            }

        }

    }

    &.--overlay {

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 900;
            background: rgba(0, 0, 0, 0.2);
        }
    
    }

    &.--overlay-exclude-header {

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 900;
            background: rgba(0, 0, 0, 0.2);
        }

        & .header {
            position: relative;
            z-index: 910;
        }
    
    }

}

@media screen and (max-width: 1499px) {    

    .frontend {

        .products-list,
        .authors-list {
    
            &.--col-4 .products-list__item:not(.swiper-slide) {

                margin-right: 22px;
    
                &:nth-child(4n+4) {
                    margin-right: 0px;
                }

            }
    
            &.--col-5 .products-list__item:not(.swiper-slide) {

                margin-right: 22px;
    
                &:nth-child(5n+5) {
                    margin-right: 0px;
                }

            }

        }

    }

}

@media screen and (max-width: 1439px) {    

    .frontend {

        .products-list,
        .authors-list {
    
            &.--col-4 .products-list__item:not(.swiper-slide) {

                margin-right: 34px;
    
                &:nth-child(4n+4) {
                    margin-right: 34px;
                }
    
                &:nth-child(3n+3) {
                    margin-right: 0px;
                }

            }
    
            &.--col-5 .products-list__item:not(.swiper-slide) {

                margin-right: 34px;
    
                &:nth-child(5n+5) {
                    margin-right: 34px;
                }
    
                &:nth-child(4n+4) {
                    margin-right: 0px;
                }

            }

        }

    }

}

@media screen and (max-width: 1169px) {    

    .frontend {

        .products-list,
        .authors-list {
    
            &.--col-4 .products-list__item:not(.swiper-slide) {

                margin-right: 87px;
    
                &:nth-child(4n+4),
                &:nth-child(3n+3) {
                    margin-right: 87px;
                }
    
                &:nth-child(2n+2) {
                    margin-right: 0px;
                }

            }
    
            &.--col-5 .products-list__item:not(.swiper-slide) {

                margin-right: 87px;
    
                &:nth-child(5n+5),
                &:nth-child(4n+4) {
                    margin-right: 87px;
                }
    
                &:nth-child(3n+3) {
                    margin-right: 0px;
                }

            }

        }

    }

}

@media screen and (max-width: 991px) {

    .frontend {

        .products-list,
        .authors-list {
    
            &.--col-4 .products-list__item:not(.swiper-slide) {

                margin-right: 186px;
    
                &:nth-child(4n+4),
                &:nth-child(3n+3),                
                &:nth-child(2n+2) {
                    margin-right: 186px;
                }
    
                &:nth-child(2n+2) {
                    margin-right: 0;
                }

            }
    
            &.--col-5 .products-list__item:not(.swiper-slide) {

                margin-right: 186px;
    
                &:nth-child(5n+5),
                &:nth-child(4n+4),                
                &:nth-child(3n+3) {
                    margin-right: 186px;
                }
    
                &:nth-child(2n+2) {
                    margin-right: 0;
                }

            }

        }

    }

}

@media screen and (max-width: 767px) {

    .frontend {

        & .pagenation {
    
            &__list {

                justify-content: center;
    
                &-item {
    
                    &.--prev,
                    &.--next {
    
                        & span {
                            display: none;
                        }
    
                    }
    
                }
    
            }
    
        }

        .products-list,
        .authors-list {

            &__item:hover {
    
                .products-list__item-wrap {
                    background-color: transparent;
                    position: static;
                }
    
                .products-list__item-detail {
                    display: block;
                }
    
                & .products-list__item-content {
    
                    padding: 0px 16px;                       
    
                    & .item-content {
    
                        &__author,
                        &__name {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
    
                        &__detail {
                            display: none;
                        }
    
                    }
    
                }
    
            } 
    
            &.--col-4 .products-list__item:not(.swiper-slide) {

                margin-right: 6px;
    
                &:nth-child(4n+4),
                &:nth-child(3n+3),                
                &:nth-child(2n+2) {
                    margin-right: 6px;
                }
    
                &:nth-child(2n+2) {
                    margin-right: 0;
                }

            }
    
            &.--col-5 .products-list__item:not(.swiper-slide) {

                margin-right: 6px;
    
                &:nth-child(5n+5),
                &:nth-child(4n+4),                
                &:nth-child(3n+3) {
                    margin-right: 6px;
                }
    
                &:nth-child(2n+2) {
                    margin-right: 0;
                }

            }

        }

        & .sortable {
    
            &__label {
                display: none;
            }
    
            &__select {
    
                &-value {

                    display: flex;
                    align-items: center;

                    &:before {
                        content: '';
                        display: inline-block;
                        width: 24px;
                        height: 17px;
                        background: url('../img/sprite.svg#sprite-sort-arrows-view') no-repeat;
                        background-size: 100% 100%;
                        margin-right: 10px;
                    }
    
                    &:after {
                        display: none;
                    }
    
                    & span {
                        margin-right: 0px;
                        top: 0;
                    }
    
                }
    
            }
    
        }
            
    }

}

@media screen and (max-width: 575px) {

    .frontend {

        & .pagenation {
    
            &__list {
    
                &-item {
                    margin: 0 8px;    
                }
    
            }
    
        }

        .swiper {
    
            &.products-swiper {
                margin: 0;
            }
    
            &.authors-swiper {
                margin: 0;
            }
            
        }

        .products-list,
        .authors-list {
    
            &.--col-4 .products-list__item:not(.swiper-slide) {

                margin-right: 30px;
    
                &:nth-child(4n+4),
                &:nth-child(3n+3) {
                    margin-right: 30px;
                }
    
                &:nth-child(2n+2) {
                    margin-right: 0;
                }

            }
    
            &.--col-5 .products-list__item:not(.swiper-slide) {

                margin-right: 30px;
    
                &:nth-child(5n+5),
                &:nth-child(4n+4),                
                &:nth-child(3n+3) {
                    margin-right: 30px;
                }
    
                &:nth-child(2n+2) {
                    margin-right: 0;
                }

            }

        }
    
    }

}

@media screen and (max-width: 565px) {

    .frontend {

        .products-list,
        .authors-list {

            &:not(.swiper-wrapper) {
                align-items: center;
                justify-content: center;
                /*flex-direction: column;*/
            }
    
            &.--col-4 .products-list__item:not(.swiper-slide) {

                margin-right: 0px;
                flex: 0 0 252px;
                width: 252px;
                height: 408px;
    
                &:nth-child(4n+4),
                &:nth-child(3n+3),                
                &:nth-child(2n+2) {
                    margin-right: 0px;
                }
    
                &:nth-child(1n+1) {
                    margin-right: 0;
                }

            }
    
            &.--col-5 .products-list__item:not(.swiper-slide) {

                margin-right: 0px;
                flex: 0 0 252px;
                width: 252px;
                height: 408px;
    
                &:nth-child(5n+5),
                &:nth-child(4n+4),                
                &:nth-child(3n+3) {
                    margin-right: 0px;
                }
    
                &:nth-child(2n+2) {
                    margin-right: 0;
                }

            }

        }
    
    }

}