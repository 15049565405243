.p404 {

    & .page-404 {

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        &__content {

            flex: 0 0 536px;

            &-title {
                border-bottom: none;
                padding-bottom: 0;
                color: $standart-text-color-2;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 20px;
            }

            &-text {
                color: $standart-text-color-2;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 20px;
            }

            &-button {

                & a {                    

                    display: block;
                    max-width: 391px;
                    border: none;
                    outline: none;
                    padding: 12px 20px;
                    width: 100%;
                    color: $white;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background-color: $special-brown-light;
                    border-radius: 5px;
                    text-align: center;
                    text-decoration: none;

                    &:hover {
                        background-color: $special-brown;
                    }

                }

            }

        }

        &__picture {

            padding-left: 100px;
            flex: 1;

            & picture,
            & img {
                display: block;
                width: 100%;
                height: auto;
            }

        }

    }

}

@media screen and (max-width: 1440px) {

    .p404 {

    }

}

@media screen and (max-width: 1170px) {

    .p404 {

    }

}

@media screen and (max-width: 992px) {

    .p404 {

        & .page-404 {
    
            &__content {    
                flex: 0 0 450px;    
            }
    
            &__picture {    
                padding-left: 50px;    
            }
    
        }

    }

}

@media screen and (max-width: 768px) {

    .p404 {

        & .page-404 {
    
            flex-direction: column;
    
            &__content {    
                flex: 0 0 100%;
                order: 1;
            }

            &__picture {    
                padding-left: 0px;
                margin-bottom: 40px;
                flex: 0 0 100%;
                order: 0;    
            }

        }
        
    }

}

@media screen and (max-width: 576px) {

    .p404 {

    }

}