.footer {

    background-color: $special-brown;

    &__column {

        padding: 40px 30px;   
        border-left: solid 1px rgba(255, 255, 255, 0.3);
        border-right: solid 1px rgba(255, 255, 255, 0.3);

        &:first-child {
            border-left: none;
            padding-left: 0;
        }

        &:last-child {
            border-right: none;
            padding-right: 0;
        }

        &.--column-1 {
            flex: 0 0 30%;
        }

        &.--column-2 {
            flex: 0 0 20%;            
        }

        &.--column-3 {
            flex: 0 0 20%;            
        }

        &.--column-4 {
            flex: 0 0 30%;            
        }
        
    }

    &__logo {
        width: 191px;
        height: 42px;
        margin-right: 30px;
        background: url('../img/sprite.svg#sprite-footer-logo-view') 0 0 no-repeat;
        background-size: 100% 100%;
        margin-bottom: 24px;
    }

    &__about {
        color: rgba(255, 255, 255, 0.80);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 137.5%;
        max-width: 378px;
        /*margin-bottom: 77px;*/
    }

    &__email {

        margin-bottom: 30px;

        & a {

            color: $palewhite;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

        }

    }

    &__social-list {

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        & .social-list {

            &__item {

                display: inline-block;
                width: 40px;
                height: 40px;
                margin-right: 12px;

                &:last-child {
                    margin-right: 0;
                }

                &.--vk {

                    background: url('../img/sprite.svg#sprite-vk-view') 0 0 no-repeat;
                    background-size: 100% 100%;

                    &:hover {                    
                        background: url('../img/sprite.svg#sprite-vk-hover-view') 0 0 no-repeat;
                        background-size: 100% 100%;
                    }

                }

                &.--ok {

                    background: url('../img/sprite.svg#sprite-ok-view') 0 0 no-repeat;
                    background-size: 100% 100%;

                    &:hover {                    
                        background: url('../img/sprite.svg#sprite-ok-hover-view') 0 0 no-repeat;
                        background-size: 100% 100%;
                    }

                }

                &.--tg {

                    background: url('../img/sprite.svg#sprite-tg-view') 0 0 no-repeat;
                    background-size: 100% 100%;

                    &:hover {                    
                        background: url('../img/sprite.svg#sprite-tg-hover-view') 0 0 no-repeat;
                        background-size: 100% 100%;
                    }

                }

                &.--wa {

                    background: url('../img/sprite.svg#sprite-wa-view') 0 0 no-repeat;
                    background-size: 100% 100%;

                    &:hover {                    
                        background: url('../img/sprite.svg#sprite-wa-hover-view') 0 0 no-repeat;
                        background-size: 100% 100%;
                    }

                }


            }

        }

    }

    &__menu-title {
        color: $white;
        font-family: Nunito;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 18px;
    }

    &__menu-list {

        list-style: none;
        margin: 0 0 30px 0;
        padding: 0;

        &.--two-columns {
            columns: 2;
        }

        & .menu-list {

            &__item {

                margin-bottom: 15px;
                line-height: 1;

                &:last-child {
                    margin-bottom: 0;
                }

                & a {

                    color: rgba(255, 255, 255, 0.80);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 137.5%;
                    text-decoration: none;
                    display: inline-block;

                    &:hover {
                        color: $white;
                    }

                }

            }

        }        

        &.--big .menu-list {

            &__item {

                margin-bottom: 34px;

                &:last-child {
                    margin-bottom: 0;
                }

                & a {

                    color: $white;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    text-decoration: none;

                    &:hover {
                        color:rgba(255, 255, 255, 0.80);
                    }

                }

            }

        }

    }

    &__button {

        display: inline-block;
        padding: 15px 30px;
        color: $white !important;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%;
        text-decoration: none;
        border: solid 1px $white;
        border-radius: 5px;
        max-width: 214px;

        &:hover {
            background-color: $white;
            color: $special-brown !important;
        }

    }

}

@media screen and (max-width: 1440px) {

    .footer {

        &__button {
            padding: 15px 12px;    
        }

    }

}

@media screen and (max-width: 1170px) {

    .footer {

        &__column {
    
            padding: 40px 30px;   
            border-left: none;
            border-right: none;
    
            &:first-child {
                border-left: none;
                padding-left: 0;
            }
    
            &:last-child {
                padding-right: 0;
                padding-left: 0;
            }
    
            &:nth-last-child(2) {
                padding-right: 0;
            }
    
            &.--column-1 {
                flex: 0 0 50%;
            }
    
            &.--column-2 {
                flex: 0 0 25%;            
            }
    
            &.--column-3 {
                flex: 0 0 25%;         
            }
    
            &.--column-4 {
                flex: 0 0 100%;
                padding-top: 0;
            }
            
        }

        &__menu-list {
                
            &.--two-columns {

                columns: auto;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 20px;              

                & .menu-list {

                    &__item {

                        margin-right: 15px;

                        &:last-child {
                            margin-bottom: 15px;
                        }


                    }

                }

            }

        }

    }

}

@media screen and (max-width: 991px) {

    .footer {

        &__column {            
    
            padding: 40px 20px;
    
            &.--column-1 {
                flex: 0 0 50%;
            }
    
            &.--column-2 {
                flex: 0 0 32%;            
            }
    
            &.--column-3 {
                flex: 0 0 18%;            
            }
            
        }

    }

}

@media screen and (max-width: 767px) {

    .footer {

        &__column {            
    
            &.--column-1 {
                flex: 0 0 100%;
                padding: 60px 0 74px 0;
            }
    
            &.--column-2 {
                flex: 0 0 50%;
                padding: 0 20px 0 0;        
            }
    
            &.--column-3 {
                flex: 0 0 50%;     
                padding: 0 0 0 20px;          
            }
    
            &.--column-4 {
                margin-top: 62px;
                padding-bottom: 45px;       
            }
            
        }

        &__about {
            margin-bottom: 25px;
        }

        &__menu-list {

            margin: 0 0 67px 0;
                
            &.--two-columns {

                columns: 2;
                display: block;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 15px;              

                & .menu-list {

                    &__item {

                        margin-right: 0;

                        &:last-child {
                            margin-bottom: 15px;
                        }


                    }

                }

            }

        }

    }

}

@media screen and (max-width: 576px) {

    .footer {

        &__column {
    
            &.--column-2 {
                flex: 0 0 60%;      
            }
    
            &.--column-3 {
                flex: 0 0 40%;              
            }
            
        }

    }


}