.home {

    &__banner {

        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        margin-bottom: 30px;

        &-left {
            flex: 0 0 932px;
            height: 470px;
            position: relative;
        }

        &-right {
            flex: 1;
            padding-left: 20px;
        }

        &-content {

            position: absolute;
            top: 50%;
            left: 30px;
            transform: translateY(-50%);
            z-index: 110;

            & .banner-content {

                &__title {
                    font-size: 90.847px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 80%;
                    color: $light-blue;
                    margin-bottom: 26px;
                }

                &__text {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: $light-blue;
                    margin-bottom: 22px;
                }

                &__btn {

                    display: block;
                    text-decoration: none;
                    color: $white !important;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 125%;
                    padding: 12px 20px;
                    max-width: 210px;
                    border-radius: 5px;
                    background-color: $special-brown-light;

                    &:hover {
                        background-color: $special-brown;
                    }

                }

            }

        }

        &-picture {

            display: block;
            border-radius: 10px;
            overflow: hidden;

            & picture,
            & img {
                display: block;
                width: 100%;
                height: 100%;
            }

        }

        &-bom {

            display: flex !important;
            flex-direction: column !important;
            height: 100% !important;

            & .banner-bom {

                &__picture {

                    position: relative;
                    margin-bottom: 18px;
                    height: 100%;
                    flex-grow: 1 !important;

                    &-label {
                        position: absolute;
                        top: 13px;
                        left: 13px;
                        background-color: $black;
                        border-radius: 30px;
                        z-index: 110;
                        color: $white;
                        padding: 11px 29px;
                    }

                    &-wrap {

                        padding: 30px 30px 0;
                        background-color: #F4F7FA;
                        border-radius: 10px;
                        height: 100%;
                        display: flex;
                        align-items: flex-end;

                        & picture,
                        & img {
                            display: block;
                            margin: 0 auto;
                        }

                    }

                }

                &__content {

                    &-author {
                        color: #9B9B9B;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        margin-bottom: 5px;                        
                    }

                    &-name {
                        color: $black;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 125%;
                        margin-bottom: 20px;
                    }

                    &-detail {

                        display: block;
                        margin-top: 15px;
                        padding: 12px 20px;
                        border-radius: 5px;
                        color: $white;
                        text-decoration: none;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        background-color: $ke-button-standart-color;
                        transition: background-color .25s linear;
                        text-align: center;
                        width: 100%;

                        &:hover {
                            background-color: $ke-button-standart-color-hover;
                            color: $white;
                        }

                        &:active,
                        &:focus {
                            background-color: $ke-button-standart-color-active;
                            color: $white;
                        }
                        
                    }

                }

            }

        }

    }

    &__books-categories {

        &__title {
            color: $black;
            font-size: 34px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 23px;
        }

        &__select-genre {

            margin-bottom: 25px;

            & span {

                display: block;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 91.667%;
                padding: 14px 19px;
                border-radius: 15px;
                color: $standart-text-color-2;
                border: 1px solid $standart-text-color-2;
                position: relative;
                cursor: pointer;

                &:after {
                    content: '';
                    display: block;
                    width: 0px;
                    height: 0px;
                    border-style: solid;
                    border-width: 6px 4.5px 0 4.5px;
                    border-color: $standart-text-color-2 transparent transparent transparent;
                    position: absolute;
                    top: 50%;
                    right: 19px;
                    transform: translateY(-50%);
                }

            }

        }

        &__list {

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 40px;

            &-item {

                padding: 0px 30px 0 0;
                margin-right: 30px;
                border-right: solid 1px #BCBCBC;
                margin-bottom: 20px;
                line-height: 1;
                height: 15px;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                    margin-right: 0;
                    border-right: none;
                }

                & a {

                    display: inline-block;
                    color: $black;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1; /*125%*/
                    text-decoration: none;
                    position: relative;
                    top: -2px;

                    &:hover {
                        opacity: 0.65;
                    }

                }

                & span {
                    color: $black;
                    display: inline-block;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1;
                    text-decoration: none;
                    position: relative;
                    top: -2px;
                }

                &.--active span {
                    color: $special-brown;
                }

            }

        }

    }

    &__products,
    &__authors {

        position: relative;

        &-title {

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            padding: 0 0.75rem;

            & .products-title,
            & .authors-title {

                &__text {
                    color: $black;
                    font-size: 34px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 18px;
                    flex: 1;
                    padding-right: 20px;
                }

                &__controls {
                    margin-left: auto;
                    flex: 0 0 110px;
                    width: 110px;
                    position: relative;
                    top: -9px;
                }

            }

        }

        &-more {

            text-align: center;
            margin-top: 40px;

            & > a {

                display: inline-block;
                padding: 19px 20px;
                color: $standart-text-color-2 !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 125%;
                border: solid 1px $standart-text-color-2;
                text-decoration: none;
                border-radius: 5px;

                &:hover {
                    color: $white !important;
                    background-color: $special-brown;
                    border-color: $special-brown;
                }

            }

        }

    }

    &__about {

        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        margin-bottom: 70px;

        &-left {
            flex: 0 0 50%;
            padding-right: 110px;
            display: flex;
            flex-direction: column;
        }

        &-right {
            flex: 0 0 50%;
        }

        &-title {
            color: $black;
            font-size: 34px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            max-width: 337px;
        }

        &-stats {

            margin-top: auto;

            & .about-stats {

                &__list {

                    display: flex;
                    align-items: stretch;
                    justify-content: flex-start;

                    &-item {

                        padding-right: 33px;
                        margin-right: 33px;
                        border-right: solid 1px #BCBCBC;

                        &:last-child {
                            padding-right: 0;
                            margin-right: 0;
                            border-right: none;
                        }

                        & .list-item {

                            &__title {
                                color: #B99264;
                                font-size: 34px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                            }
    
                            &__text {
                                color: #424242;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 125%;
                            }

                        }

                    }
    
                }

            }

        }

        &-desc {

            & p {   

                color: #212121;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

            }

        }

    }

    &__blog {

        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;

        &-title {
            font-size: 34px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: $standart-text-color;
            margin-bottom: 20px;
            flex: 0 0 100%;
        }

        &-left {
            flex: 0 0 50%;
            padding-right: 10px;
        }

        &-right {
            flex: 0 0 50%;
            padding-left: 10px;           
        }

        &-item {

            position: relative;

            & > a {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 110;
            }

            & .blog-item {

                &__picture {

                    margin-bottom: 20px;
                    border-radius: 10px;
                    overflow: hidden;

                    & picture,
                    & img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }

                }

                &__date {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 125%;
                    color: $standart-text-color-2;
                    margin-bottom: 15px;                    
                }

                &__title {
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 115.385%;
                    color: $standart-text-color-2;
                    margin-bottom: 5px;  
                }

                &__anounce {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 137.5%;
                    color: $standart-text-color-2;
                }

            }

            &:hover .blog-item {

                &__title {
                    opacity: 0.7;
                }

            }

        }

        &-list {
            
            border-radius: 10px;
            padding: 16px 13px;
            background-color: $light-blue; 
            /*height: 100%;*/
            width: 100%;

            & .blog-list {

                &__item {

                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 20px 0;
                    border-bottom: solid 1px $standart-text-color-2;

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
        
                    & > a {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 110;
                    }

                    &-picture {

                        flex: 0 0 219px;
                        border-radius: 6px;
                        overflow: hidden;

                        & picture,
                        & img {
                            display: block;
                            width: 100%;
                            height: auto;
                        }

                    }

                    &-content {

                        flex: 1;
                        padding-left: 15px;

                        & .item-content {

                            &__date {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 125%;
                                color: $standart-text-color-2;
                                margin-bottom: 15px;
                            }

                            &__title {
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 130%;
                                color: $standart-text-color-2;
                                margin-bottom: 10px;
                            }

                            &__anounce {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 125%;
                                color: $standart-text-color-2;
                            }

                        }

                    }

                }

                &__item:hover .blog-list__item-content .item-content__title {
                    opacity: 0.7;
                }

            }

        }

        &-showall {

            flex: 0 0 100%;
            margin-top: 50px;
            text-align: center;

            & > a {

                display: block;
                margin: 0 auto;
                max-width: 260px;
                padding: 20px;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 125%;
                color: $standart-text-color-2 !important;
                text-decoration: none;
                border-radius: 5px;
                border: 1px solid $standart-text-color-2;
    
                &:hover {
                    border-color: $special-brown-light;
                    color: $white !important;
                    background-color: $special-brown-light;
                }

            }

        }

    }

}

@media screen and (max-width: 1499x) {

    .home {        

        &__about {

            &-left {
                flex: 0 0 55%;
                padding-right: 90px;
            }

            &-right {
                flex: 0 0 45%;
            }

        }

    }

}

@media screen and (max-width: 1439px) {

    .home {

        &__banner {

            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: flex-start;
            margin-bottom: 30px;

            &-left {
                flex: 0 0 100%;
                height: auto;
                position: relative;
                margin-bottom: 30px;
            }

            &-right {
                flex: 0 1 391px;
                padding-left: 0px;
                margin: 0 auto;
            }

        }

        &__about {

            &-left {
                flex: 0 0 60%;
                padding-right: 70px;
            }

            &-right {
                flex: 0 0 40%;
            }

        }

    }

}

@media screen and (max-width: 1169px) {

    .home {

        &__about {

            &-left {
                flex: 0 0 100%;
                padding-right: 0px;
                margin-bottom: 60px;
            }

            &-right {
                flex: 0 0 100%;
            }            

            &-title {
                margin-bottom: 60px;
            }            

            &-stats {

                & .about-stats {

                    &__list {

                        &-item {

                            &:last-child {
                            }

                            & .list-item {
        
                                &__text {
                                    max-width: 175px;
                                }

                            }

                        }
        
                    }

                }

            }        

        }

    }

}

@media screen and (max-width: 991px) {

    .home {

        &__blog {
    
            &-left {
                flex: 0 0 100%;
                padding-right: 0px;
                margin-bottom: 37px;
            }
    
            &-right {
                flex: 0 0 100%;
                padding-left: 0px;           
            }

        }

    }

}

@media screen and (max-width: 767px) {

    .home {

        &__banner {
    
            &-content {
    
                left: 30px;
    
                & .banner-content {
    
                    &__title {
                        font-size: 48px;
                        margin-bottom: 24px;
                    }
    
                    &__text {
                        font-size: 16px;
                        margin-bottom: 20px;
                    }
    
                    &__btn {
                        padding: 11px 16px;
                        max-width: 210px;    
                    }
    
                }
    
            }
    
        }

        &__books-categories {

            margin-bottom: 40px;            

            &__list {

                display: none;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 0px;

                &-item {

                    flex: 0 0 50%;
                    padding: 7px 0px;
                    margin-right: 0px;
                    border-right: solid 1px #BCBCBC;
                    margin-bottom: 0px;
                    line-height: 1;
                    height: auto;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                        margin-right: 0;
                        border-right: solid 1px #BCBCBC;
                    }

                    &:nth-child(odd) {
                        padding-left: 20px;
                    }

                    &:nth-child(even) {
                        border-right: none;
                        padding-left: 50px;
                        padding-right: 20px;
                    }

                    & a {
                        font-size: 24px;
                        position: relative;
                        top: 0px;
                    }

                }

            }

            &__select-genre.--visible {                

                & span {

                    &:after {
                        transform: translateY(-50%) rotate(180deg);
                        transform-origin: center center;
                    }

                }

            }

            &__select-genre.--visible + .home__books-categories__list {
                display: flex;
            }

        }

        &__about {

            &-stats {

                & .about-stats {

                    &__list {

                        &-item {

                            & .list-item {
        
                                &__title {
                                    font-size: 25px;
                                }

                            }

                        }
        
                    }

                }

            } 

        }

    }

}

@media screen and (max-width: 575px) {

    .home {
        
        &__banner {

            &-right {
                flex: 0 1 340px;
            }

            &-bom {
    
                & .banner-bom {
    
                    &__picture {
    
                        &-wrap {
    
                            & picture,
                            & img {
                                max-width: 100%;
                            }
    
                        }
    
                    }

                }

            }

        }

        &__books-categories {

            margin-bottom: 40px;

            &__list {

                &-item {

                    &:nth-child(odd) {
                        padding-left: 0px;
                    }

                    &:nth-child(even) {
                        padding-left: 20px;
                        padding-right: 0px;
                    }

                }

            }

        }

        &__about {            

            &-stats {

                & .about-stats {

                    &__list {

                        display: flex;
                        flex-direction: column;

                        &-item {

                            width: 100%;
                            padding-right: 0px;
                            margin-right: 0px;
                            border-right: none;
                            padding-bottom: 33px;
                            margin-bottom: 33px;
                            border-bottom: solid 1px #BCBCBC;

                            &:last-child {
                                padding-bottom: 0;
                                margin-bottom: 0;
                                border-bottom: none;
                            }

                            & .list-item {

                                &__title {
                                    font-size: 34px;
                                }
        
                                &__text {
                                    max-width: 100%;
                                }

                            }

                        }
        
                    }

                }

            }
        }

        &__products,
        &__authors {
    
            & .products-title,
            & .authors-title {

                &__text {
                    font-size: 31px;
                }

            }
    
        }

    }

}

@media screen and (max-width: 540px) {

    .home {        

        &__banner {
    
            &-content {
    
                left: 20px;
    
                & .banner-content {
    
                    &__title {
                        font-size: 24px;
                        margin-bottom: 14px;
                        line-height: 90%;
                    }
    
                    &__text {
                        font-size: 11px;
                        margin-bottom: 14px;
                    }
    
                    &__btn {
                        padding: 7px 34px;
                        display: inline-block;   
                    }
    
                }
    
            }
    
        }

        &__books-categories {
    
            &__select-genre {
    
                & span {
                    font-size: 18px;
                    line-height: 122.222%;    
                }
    
            }

            &__list {

                &-item {

                    & a {
                        font-size: 16px;
                    }

                }

            }

        }       

        &__blog {

            &-list {

                & .blog-list {

                    &__item {

                        &-picture {
                            flex: 0 0 100%;
                            margin-bottom: 20px;
                        }

                        &-content {

                            flex: 0 0 100%;
                            padding-left: 0px;

                        }

                    }

                }

            }

        }

    }

}