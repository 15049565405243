.header {

    background-color: $special-gray;
    padding: 27px 0 20px 0;

    &__top {
    }

    &__bottom {
        margin-top: 28px;
    }

    &__logo {

        width: 191px;
        height: 42px;
        margin-right: 30px;

        & a {
            display: block;
            width: 100%;
            height: 100%;
            background: url('../img/sprite.svg#sprite-header-logo-view') 0 0 no-repeat;
            background-size: 100% 100%;
        }

    }

    &__break.--sm,
    &__break.--md {
        display: none;
        width: 0;
        flex-basis: 100%;
    }

    &__books {

        margin-right: 30px;

        & > span {

            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 11px 40px;
            border-radius: 5px;
            color: $white;
            text-decoration: none;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background-color: $ke-button-standart-color;
            transition: background-color .25s linear;
            cursor: pointer;

            &:before {
                content: '';
                display: inline-block;
                width: 14px;
                height: 14px;
                background: url('../img/sprite.svg#sprite-books-btn-view') 0 0 no-repeat;
                background-size: 100% 100%;
                margin-right: 10px;
            }

            & > span {

            }

            &:hover {
                background-color: $ke-button-standart-color-hover;
                color: $white;
            }

            &:active,
            &:focus {
                background-color: $ke-button-standart-color-active;
                color: $white;
            }

        }

        &-overlay {

            position: fixed;
            width: 100vw;
            height: 100vh;
            z-index: 950;
            display: none;
            background-color: rgba(47, 47, 47, 0.3);
            top: 0;
            left: 0;

            .--open-catalog-book-modal & {
                display: block;
            }

        }

        &-modal {

            position: fixed;
            background-color: $white;
            width: 460px;
            height: 100vh;
            top: 0;
            left: -500px;
            padding: 24px 22px 24px 35px;
            z-index: 955;
            border-radius: 0px 10px 10px 0px;
            box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
            display: flex;
            flex-direction: column;
            transition: left 0.5s ease-out;

            .--open-catalog-book-modal & {
                left: 0px;
            }
            
            & .books-modal {

                &__close {
        
                    display: block;
                    position: absolute;
                    z-index: 100;
                    width: 23px;
                    height: 20px;
                    right: -33px;
                    top: 28px;
                    background: url('../img/sprite.svg#sprite-catalog-book-modal-close-view') no-repeat;
                    transition: opacity .25s linear;
                    cursor: pointer;
        
                    &:hover {
                        opacity: 0.7;
                    }
        
                }
    
                &__title {
                    color: $standart-text-color-2;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 3px;
                    padding-left: 15px;
                }
    
                &__show-all {
    
                    margin-bottom: 46px;
                    padding-left: 15px;
    
                    & a {
    
                        text-decoration: none;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        color: $special-brown;
    
                        &:hover {
                            color: $special-brown-light;
                        }
    
                    }
    
                }
    
                &__nav {
    
                    margin: 0;
                    padding: 0;
                    flex: 1;
                    overflow-x: auto;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    
                    &::-webkit-scrollbar {
                        display: none;
                    }
    
                    &-item {
    
                        margin: 0;
                        padding: 0;
    
                        & > a {
    
                            display: inline-block;
                            padding: 8px 36px 8px 15px;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-decoration: none;
                            color: $standart-text-color-2;
                            background-color: transparent;
    
                            &:hover {

                                color: $white;
                                background-color: $special-brown-light;
                                position: relative;

                                &:after {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    z-index: 110;
                                    top: 50%;
                                    right: 15px;
                                    transform: translateY(-50%);
                                    width: 11px;
                                    height: 13px;
                                    background: url('../img/sprite.svg#sprite-catalog-book-modal-menu-arrow-view') no-repeat;
                                }

                            }
    
                        }
    
                    }
    
                }

            }

        }

    }

    &__search {

        flex: 1;
        margin-right: 30px;

        &-form {

            position: relative;
            margin-bottom: 0;

            & input[type="text"] {

                width: 100%;
                border-radius: 5px;
                background: $white;
                padding: 11px 76px 11px 20px;
                color: $black;
                border: 0;
                line-height: 1;
                outline: none;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                &::-webkit-input-placeholder,
                &::-moz-placeholder,
                &::-ms-placeholder,
                &::placeholder {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    color: #555555;
                }

            }

            & button {

                position: absolute;
                top: 2px;
                right: 2px;
                z-index: 110;
                background-color: $special-brown;
                border: 0;
                border-radius: 4px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                padding: 8px 15px;

                &:hover {
                    background-color: $ke-button-standart-color-hover;
                }

                &:before {
                    content: '';
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url('../img/sprite.svg#sprite-zoom-search-view') no-repeat;
                }

            }

        }

        &-results {

            display: none;
            position: relative;

            &.--show {
                display: block;
            }

            & .search-results {

                &__list {
                    
                    margin-top: 5px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 115;
                    width: 100%;
                    background-color: $white;
                    padding: 10px 10px 10px 7px;
                    border-radius: 5px;

                    &-item {

                        & a {

                            display: block;
                            padding: 12px 14px;
                            background-color: transparent;
                            color: $standart-text-color-2;
                            text-decoration: none;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;

                            &:hover {
                                background-color: rgba(185, 146, 100, 0.50);
                            }

                        }

                    }

                }

            }

        }

    }

    &__favorites {

        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $palewhite;
        text-decoration: none;
        margin-right: 30px;

        &-icon {
            display: block;
            width: 32px;
            height: 32px;
            background: url('../img/sprite.svg#sprite-favorites-view') no-repeat;
        }

        &-text {
            color: $palewhite;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
        }

        &:hover {

            color: $ke-button-standart-color;

            & .header__favorites-icon {
                background: url('../img/sprite.svg#sprite-favorites-hover-view') no-repeat;
            }
    
            & .header__favorites-text {
                color: $ke-button-standart-color;
            }

        }


    }

    &__signin {

        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $palewhite;
        text-decoration: none;

        &-icon {
            display: block;
            width: 32px;
            height: 32px;
            background: url('../img/sprite.svg#sprite-signin-view') no-repeat;
        }

        &-text {
            color: $palewhite;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
        }

        &:hover {

            color: $ke-button-standart-color;

            & .header__signin-icon {
                background: url('../img/sprite.svg#sprite-signin-hover-view') no-repeat;
            }
    
            & .header__signin-text {
                color: $ke-button-standart-color;
            }

        }


    }

    &__hamburgers {
        margin-left: 30px;
    }

    &__nav {

        margin: 0;
        padding: 0;
        list-style: none;

        &-item {

            margin: 0 66px 0 0;
            padding: 0;

            &:last-child {
                margin-right: 0;
            }

            & a {

                display: inline-block;
                color: $palewhite;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-decoration: none;

                &:hover {
                    color: $ke-button-standart-color-hover;
                }

            }

            &.--active a,
            &.--active a:hover {
                color: $special-brown;
                cursor: default;
            }

            &.--dropdown {

                position: relative;

                & > span {
                    display: none;
                }

                & > div {

                    display: none;
                    position: absolute;
                    z-index: 220;
                    top: 30px;
                    left: 0px;
                    padding: 13px 10px;
                    background: $special-gray;

                    & > ul {

                        margin: 0;
                        padding: 0;
                        list-style: none;

                        & > li {

                            margin: 0;
                            padding: 0;
                            list-style: none;

                            & > a {

                                display: block;
                                color: $palewhite;
                                padding: 7px 14px;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                text-decoration: none;
                                white-space: nowrap;

                                &:hover {
                                    background: $special-brown;
                                }

                            }

                        }

                    }

                }

                &:hover {

                    & > a {
                        background: transparent;
                    }

                    & > div {
                        display: block;
                    }

                }

            }

        }

    }

    &__random-book,
    &__email {

        & a {

            display: inline-block;
            color: $palewhite;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration: none;
            border-bottom: solid 1px #CBBDBD;

            &:hover {
                color: $ke-button-standart-color-hover;
                border-bottom-color: transparent;
            }

        }

    }

    &__random-book {
        margin-right: 50px;
    }


}

.title-search-result {

    border: none;
    overflow: hidden;
    z-index: 998;

    & .header__search {        

        &-results {

            display: block;
            position: relative;

            & .search-results {

                &__list {
                    
                    margin-top: 5px;
                    position: static !important;
                    top: auto !important;
                    left: auto !important;
                    z-index: inherit !important;
                    width: 100%;
                    background-color: $white;
                    padding: 10px 10px 10px 7px;
                    border-radius: 5px;

                    &-item {

                        & a {

                            display: block;
                            padding: 12px 14px;
                            background-color: transparent;
                            color: $standart-text-color-2;
                            text-decoration: none;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;

                            &:hover {
                                background-color: rgba(185, 146, 100, 0.50);
                            }

                        }

                    }

                }

            }

        }

    }

}

@media screen and (max-width: 1439px) {

    .header {

        &__nav {
    
            &-item {
    
                margin: 0 46px 0 0;

            }

        }

        &__random-book {
            margin-right: 40px;
        }

    }

}

@media screen and (max-width: 1169px) {

    .header {

        &__nav {
    
            &-item {
    
                margin: 0 22px 0 0;

            }

        }

        &__random-book {
            margin-right: 35px;
        }

    }

}

@media screen and (max-width: 991px) {

    .header {

        position: fixed;
        padding: 28px 0 33px 0;
        z-index: 500;
        width: 100%;
        height: 109px;
        overflow: hidden;

        &__logo {
            order: 0;
        }

        &__break.--md {
            order: 1;
        }

        &__books {
            order: 3;
        }

        &__favorites {
            order: 4;
        }

        &__signin {
            order: 5;
        }

        &__hamburgers {
            order: 6;
        }

        &__search {
            order: 7;
            flex: 0 0 100%;
            width: 100%;
            margin-top: 27px;
            margin-right: 0;
        }

        &__bottom {
            width: 100%;
        }  

        &__nav {
            width: 100%;
            margin-bottom: 31px;
        }        

    }

}

@media screen and (max-width: 767px) {

    .header {

        height: 178px;

        &__logo {
            order: 0;
            margin: 0 auto 27px;
        }

        &__break.--md {
            display: block;
            order: 1;
        }

        &__books {
            order: 3;
            margin-right: 0px;
        }

        &__favorites {
            order: 4;
            margin-right: 0px;
        }

        &__signin {
            order: 5;
        }

        &__hamburgers {
            order: 6;
            margin-left: 0px;
        }

        &__search {
            order: 7;
        }       

    }

}

@media screen and (max-width: 575px) {

    .header {

        height: 100px;
        padding: 32px 0;

        &__logo {
            width: 167px;
            height: 36px;
            margin: 0 0 32px 0;
        }

        &__hamburgers {
            order: 1;
            margin-left: auto;
            margin-bottom: 32px;
        }

        &__break.--md {
            display: none;
        }            

        &__break.--sm {
            display: block;
            order: 2;
        }

        &__favorites {
    
            &-text {
                display: none;
            } 
    
    
        }
    
        &__signin {
    
            &-text {
                display: none;
            }    
    
        }

        &__books {

            &-overlay {

                .--open-catalog-book-modal & {
                    display: none;
                }

            }

            &-modal {

                display: none;
                background-color: $special-gray;
                width: 100%;
                height: calc(100vh - 100px);
                top: 100px;
                left: 0;
                padding: 0 1.5rem;
                border-radius: 0px;
                box-shadow: none;
                transition: none;

                .--open-catalog-book-modal & {
                    display: flex;
                }
                
                & .books-modal {

                    &__close {
                        display: none;            
                    }
        
                    &__title {
                        color: $white;
                        font-size: 16px;
                        margin-bottom: 20px;
                        padding-left: 0;
                        padding-bottom: 32px;
                        border-bottom: solid 1px $white;
                    }
        
                    &__show-all {        
                        margin-bottom: 20px;
                        padding-left: 0;        
                    }
        
                    &__nav {

                        margin-bottom: 10px;
        
                        &-item {

                            margin-bottom: 9px;

                            &:last-child {
                                margin-bottom: 0;
                            }
        
                            & > a {
        
                                padding: 0;
                                font-size: 16px;
                                font-weight: 400;
                                color: $white;
                                background-color: transparent;
        
                                &:hover {

                                    color: $special-brown;
                                    background-color: transparent;

                                    &:after {
                                        display: none;
                                    }

                                }
        
                            }
        
                        }
        
                    }

                }

            }

        }
        
    }

}