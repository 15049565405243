$fa-font-path: '../fonts/FontAwesome'; 

$white: #FFFFFF;
$palewhite: #F4F4F4;
$black: #000000;
$red: #D30000;
$red-light: #ff9090;
$red-dark: #9D1616;
$special-gray: #383E45;
$special-brown: #AE824E;
$special-brown-light: #B99264;
$pale-cornflower-blue: #F1F3F9;
$gray: #505050;
$gray-light: #CACBCF;
$green: #62c56b;
$blue: #336298;
$light-blue: #F4F7FA;

$body-bg: $white;

$standart-text-color: #222222;
$standart-text-color-2: #383E45;
$standart-text-dark-color: $gray;
$standart-text-light-color: #FDFDFE;

$footer-content-bg: $special-brown;
$footer-copyright-bg: #E2F4FB;

$ke-button-standart-color: $special-brown;
$ke-button-standart-color-hover: #c99f6e;
$ke-button-standart-color-active: #936c3f;
$ke-button-standart-color-disabled: #e7c7a1;

$hamburger-layer-color: $white;
$hamburger-layer-border-radius: 0px;
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;

$hamburger-layer-width: 25px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 6px;

// $input-* are shared across most of our form controls (and not buttons).

$input-padding-y:                       13px;
$input-padding-x:                       19px;
$input-font-family:                     'Hero New', sans-serif;
$input-font-size:                       16px;
$input-font-weight:                     300;
//$input-line-height:                     138%;

$input-padding-y-sm:                    13px;
$input-padding-x-sm:                    19px;
$input-font-size-sm:                    16px;

$input-padding-y-lg:                    13px;
$input-padding-x-lg:                    19px;
$input-font-size-lg:                    16px;

$input-bg:                              $white;
//$input-disabled-color:                  null;
$input-disabled-bg:                     $gray-light;
//$input-disabled-border-color:           null;

$input-color:                           $gray;
$input-border-color:                    $pale-cornflower-blue;
$input-border-width:                    1px;
$input-box-shadow:                      none;

$input-border-radius:                   15px;
$input-border-radius-sm:                15px;
$input-border-radius-lg:                15px;

$input-focus-bg:                        $white;
$input-focus-border-color:              $special-brown;
$input-focus-color:                     $gray;
//$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                none;

$input-placeholder-color:               $gray;
$input-plaintext-color:                 $black;

/*$input-height-border:                   $input-border-width * 2;

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y);
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y * .5);

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-sm:                       add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg:                       add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-color-width:                      3rem;*/

// select
$form-select-padding-y:             $input-padding-y;
$form-select-padding-x:             $input-padding-x;
$form-select-font-family:           $input-font-family;
$form-select-font-size:             $input-font-size;
//$form-select-indicator-padding:     $form-select-padding-x * 3; // Extra padding for background-image
$form-select-font-weight:           $input-font-weight;
//$form-select-line-height:           $input-line-height;
$form-select-color:                 $input-color;
$form-select-bg:                    $input-bg;
//$form-select-disabled-color:        $input-disabled-bg;
//$form-select-disabled-bg:           $input-disabled-bg;
//$form-select-disabled-border-color: $input-disabled-border-color;
$form-select-bg-position:           right $form-select-padding-x center;
$form-select-bg-size:               16px 12px; // In pixels because image dimensions
//$form-select-indicator-color:       $gray-800;
//$form-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");

//$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 + $form-select-indicator-padding;
//$form-select-feedback-icon-position:    center right $form-select-indicator-padding;
//$form-select-feedback-icon-size:        $input-height-inner-half $input-height-inner-half;

$form-select-border-width:        $input-border-width;
$form-select-border-color:        $input-border-color;
$form-select-border-radius:       $input-border-radius;
$form-select-box-shadow:          none;

$form-select-focus-border-color:  $input-focus-border-color;
//$form-select-focus-width:         $input-focus-width;
$form-select-focus-box-shadow:    none;

$form-select-padding-y-sm:        $input-padding-y-sm;
$form-select-padding-x-sm:        $input-padding-x-sm;
$form-select-font-size-sm:        $input-font-size-sm;
$form-select-border-radius-sm:    $input-border-radius-sm;

$form-select-padding-y-lg:        $input-padding-y-lg;
$form-select-padding-x-lg:        $input-padding-x-lg;
$form-select-font-size-lg:        $input-font-size-lg;
$form-select-border-radius-lg:    $input-border-radius-lg;

//$form-select-transition:          $input-transition;

// Checkboxes

$form-check-input-focus-box-shadow: none;

$form-check-input-checked-bg-color: $special-brown;

$progress-height: 0.4rem;
$progress-bg: $pale-cornflower-blue;
$progress-bar-bg: $special-brown;

// tooltips
$tooltip-font-size: 11px;
$tooltip-max-width: 250px;
$tooltip-bg: $special-gray;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1170px,
    2xl: 1440px,
    3xl: 1500px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    2xl: 1380px,
    3xl: 1440px
);

//$grid-columns: 24 !default;