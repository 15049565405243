.catalog {

    &__title {

        & h1 {
            color: $black;
            font-size: 34px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-bottom: 10px;
            border-bottom: solid 1px #BAC6D1;
            margin-bottom: 20px;
        }

    }

    &__container {

        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;

        &-sidebar {    
            flex: 0 0 252px;
            width: 252px;    
        }

        &-content {
            flex: 1;
            padding-left: 38px;
        }

    }

    &__filter {        

        &.form {            
            position: sticky;
            top: 0px;
        }

        &-title {

            display: none;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding-bottom: 20px;
            border-bottom: solid 1px $light-blue;

            & .filter-title {

                &__close {

                    display: inline-block;
                    margin-right: auto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    color: $standart-text-color-2;
                    line-height: 111.11%;
                    cursor: pointer;
                    flex: 1;

                    &:hover {
                        opacity: 0.7;
                    }

                }

                &__text {
                    flex: 1 1 auto;
                    display: inline-block;
                    font-size: 34px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 117.647%;
                }

            }

        }

        &-block {

            padding: 20px 0;
            border-bottom: solid 1px $light-blue;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-top: 30px;
                border-bottom: none;
            }

            & .filter-block {

                &__title {

                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                    }

                    &-text {
                        color: $standart-text-color-2;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 125%;
                    }

                    &-arrow {
                        margin-left: auto;
                        width: 12px;
                        height: 12px;
                        background: url('../img/sprite.svg#sprite-expand-filter-arrow-view') 0 0 no-repeat;
                    }

                }

                &__content {

                    height: 0;
                    overflow: hidden;
                    transition: height 0.3s linear;

                    &-wrap {

                        padding-top: 18px;

                        &.--prices {
                            padding-bottom: 21px;
                        }

                    }

                    &-row {

                        margin-bottom: 14px;

                        &.--disabled {
                            
                            position: relative;

                            &:before {
                                content: '';
                                display: block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                z-index: 150;
                                background-color: rgba(255, 255, 255, 0.8);
                            }

                        }

                    }

                    &-prices {

                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        & .content-prices {

                            &__from,
                            &__to {

                                flex: 1;

                                & input {
                                    border-radius: 2px;
                                    border: 1px solid $light-blue;
                                    background: $light-blue;
                                    outline: none;
                                    text-align: center;
                                    padding: 10px;
                                    color: $standart-text-color-2;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 125%;
                                    width: 100%;
                                }

                            }

                            &__separator {
                                flex: 0 0 20px;
                                height: 1px;
                                margin: 0 7px;
                                background-color: $standart-text-color-2;
                            }



                        }

                    }

                    &-slider {

                        margin-top: 10px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: flex-start;

                        & .content-slider {

                            &__left,
                            &__right {
                                flex: 0 0 50%;
                                color: $standart-text-color-2;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 142.857%;
                            }

                            &__right {
                                margin-left: auto;
                                text-align: right;
                            }

                            &__bar {

                                width: 100%;
                                margin-top: 10px;

                                &.noUi-target {
                                    background: $light-blue;
                                    border-radius: 0;
                                    border: none;
                                    box-shadow: none;
                                    padding: 0 9px;
                                }

                                & .noUi-connect {
                                    background-color: $special-brown-light;
                                    border-radius: 0;
                                }

                                &.noUi-horizontal {
                                    height: 1px;
                                }

                                &.noUi-horizontal .noUi-handle.noUi-handle-lower,
                                &.noUi-horizontal .noUi-handle.noUi-handle-upper {
                                    width: 18px;
                                    height: 18px;
                                    right: -9px;
                                    top: -8px;
                                }

                                &.noUi-horizontal .noUi-handle.noUi-handle-lower {
                                    right: -9px;
                                }

                                & .noUi-handle {
                                    border: none;
                                    border-radius: 100%;
                                    background: $special-brown-light;
                                    cursor: default;
                                    box-shadow: none;

                                    &:before,
                                    &:after {
                                        display: none;
                                    }
                                }

                            }

                        }

                    }

                    &-buttons {

                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: flex-start;

                        & .content-buttons {

                            &__left,
                            &__right {
                                flex: 0 0 50%;
                            }

                            &__left {
                            }

                            &__right {
                                padding-left: 10px;
                                text-align: center;
                            }

                            &__submit {

                                display: block;
                                border: none;
                                outline: none;
                                padding: 11px 20px;
                                width: 100%;
                                color: $white;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                background-color: $special-brown-light;
                                border-radius: 5px;

                                &:hover {
                                    background-color: $special-brown;
                                }

                            }

                            &__reset {

                                display: inline-block;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                padding: 0 0 1px 0;
                                border: none;
                                outline: none;
                                background: none;
                                border-bottom: solid 1px $standart-text-color-2;
                                color: $standart-text-color-2;

                                &:hover {
                                    border-bottom: none;
                                    opacity: 0.7;
                                }

                            }

                        }

                    }

                }
                
            }

            &.--expanded .filter-block {

                &__title {

                    &-arrow {
                        transform: rotate(180deg);
                        transform-origin: center center;
                    }

                }

                &__content {
                    height: auto;
                }

            }

        }

    }

    &__products {

        &-filters {
            
            margin-bottom: 28px;
            width: 100%;

            & .products-filters {
    
                &__filter {

                    display: none;
                    align-items: center;
                    justify-content: flex-start;
                    cursor: pointer;

                    &:before {
                        content: '';
                        display: block;
                        width: 23px;
                        height: 21px;
                        margin-right: 10px;
                        background: url('../img/sprite.svg#sprite-mobile-filter-view') no-repeat;
                    }
                    
                    & span {
                        color: $standart-text-color-2;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                }
    
                &__sort {
                    display: flex;
                    justify-content: flex-end;
                    margin-left: auto;
                }
    
            }

        }

        &-list {

            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: space-between;
            margin-bottom: 20px;

            & .products-list {

                &__item {
                    margin-bottom: 40px;
                }

            }

        }

    }

    &-product {

        &__top {
            margin-bottom: 40px;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: flex-start;
        }

        &__content {
            padding-left: 140px;
            max-width: 1170px;
            margin-bottom: 60px;
        }

        &__picture {

            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            flex: 0 1 664px;

            & .product-picture {

                &__slides {

                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex: 0 0 120px;
                    width: 120px;

                    &-item {

                        margin-bottom: 20px;
                        border-radius: 10px;
                        overflow: hidden;
                        cursor: pointer;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        & picture,
                        & img {
                            display: block;
                            max-width: 100%;
                        }

                    }

                }

                &__photo {

                    padding-left: 20px;
                    position: relative;

                    &-list {

                        width: 524px;
                        height: auto;
                        overflow: hidden;

                        & .photo-list {

                            &__item {
                                border-radius: 10px;
                                display: inline-block;
                            }

                        }

                    }

                    &-add-to-favourite {

                        cursor: pointer;
                        display: none;
                        width: 60px;
                        height: 60px;
                        border-radius: 5px;
                        border: 1px solid #DCE4EE;
                        background: #E7EAEE;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: 16px;
                        right: 16px;
                        z-index: 220;

                        &:before {
                            content: '';
                            display: block;
                            width: 32px;
                            height: 32px;
                            background: url('../img/sprite.svg#sprite-catalog-item-favourites-mobile-view') 0 0 no-repeat;
                        }

                        &:hover {

                            &:before {
                                background: url('../img/sprite.svg#sprite-catalog-item-favourites-mobile-hover-view') 0 0 no-repeat;
                            }

                        }

                        &.--active,
                        &.--active:hover {

                            &:before {
                                background: url('../img/sprite.svg#sprite-catalog-item-favourites-mobile-active-view') 0 0 no-repeat;
                            }

                        }

                    }

                    & picture,
                    & img {
                        border-radius: 10px;
                        display: block;
                        max-width: 524px;
                    }

                }

            }

        }

        &__info {

            flex: 1;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;

            &-title {

                margin-bottom: 30px;

                & h1 {
                    color: $black;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 125%;
                    margin-bottom: 0 !important;
                    padding-bottom: 0 !important;
                    border-bottom: 0 !important;
                }

            }

            &-rating {

                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 30px;

                & .info-rating {

                    &__stars {

                        display: flex;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        justify-content: flex-start;
                        margin-right: 15px;

                        &-item {            

                            width: 33px;
                            height: 28px;                            
                            background: url('../img/sprite.svg#sprite-review-star-empty-view') 0 0 no-repeat;
                            margin-right: 7px;

                            &:last-child {
                                margin-right: 0;
                            }

                            &.--half {
                                background: url('../img/sprite.svg#sprite-review-star-half-view') 0 0 no-repeat;
                            }

                            &.--full {
                                background: url('../img/sprite.svg#sprite-review-star-full-view') 0 0 no-repeat;
                            }

                        }

                    }

                    &__count {
                        color: $black;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 125%;
                        margin-right: 40px;
                    }

                    &__review {

                        display: inline-block;
                        color: #1F1F1F;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        border-bottom: solid 1px #1F1F1F;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.6;
                            border-bottom-color: transparent;
                        }

                    }

                }

            }

            &-props {

                width: 100%;

                & .info-props {

                    &__list {

                        &-item {

                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: flex-start;
                            margin-bottom: 20px;

                            & .list-item {

                                &__name {
                                    flex: 0 0 120px;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 125%;
                                    color: $special-gray;
                                }

                                &__value {

                                    flex: 1;
                                    padding-left: 30px;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 125%;
                                    color: $special-brown-light;

                                    & a {

                                        display: inline-block;
                                        padding-left: 30px;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 700;
                                        line-height: 125%;
                                        color: $special-brown-light !important;
                                        text-decoration: none;


                                        &:hover {
                                            color: $special-brown !important;
                                        }

                                    }

                                }

                            }

                        }

                    }

                }

            }

            &-other {

                margin-bottom: 30px;

                & a {

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 125%;
                    text-decoration: none;
                    color: $special-brown-light !important;

                    &:hover {
                        color: $special-brown !important;
                    }

                }

            }

            &-offers {

                padding: 30px;
                background-color: $light-blue;
                border-radius: 10px;
                margin-top: auto;
                width: 100%;

                & .info-offers {

                    &__blocks {

                        padding-bottom: 10px;
                        border-bottom: solid 1px $special-gray;
                        margin-bottom: 30px;

                        &-item {

                            & .blocks-item {

                                &__title {

                                    font-family: Nunito;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 125%;
                                    margin-bottom: 10px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;

                                    & > span.info {
                                        width: 22px;
                                        height: 22px;
                                        background: url('../img/sprite.svg#sprite-offer-info-view') no-repeat;
                                        cursor: pointer;
                                        margin-left: 7px;
                                    }
                                }

                                &__list {

                                    display: flex;
                                    flex-wrap: wrap;
                                    align-items: stretch;
                                    justify-content: flex-start;

                                    &-offer {

                                        margin: 0 17px 20px 0;
                                        padding: 11px;
                                        width: 190px;
                                        text-align: center;
                                        cursor: pointer;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        border-radius: 10px;
                                        border: solid 2px $special-gray;

                                        &:hover {                 
                                            border-color: #9b9b9b;
                                        }

                                        &.--selected,
                                        &.--selected:hover {                                        
                                            border-color: $special-brown-light;
                                        }

                                        &:last-child {
                                            margin-right: 0;
                                        }

                                        & .list-offer {

                                            &__parameter {
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 700;
                                                line-height: 142.857%;
                                                color: $special-gray;
                                                margin-bottom: 2px;
                                            }

                                            &__price {
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 700;
                                                line-height: 142.857%;
                                                color: $special-gray;
                                            }

                                        }

                                    }

                                }

                            }

                        }

                    }

                    &__buy-block {

                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: flex-start;

                        & .buy-block {

                            &__price {
                                color: $red;
                                font-size: 38.803px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 90.909%;
                                margin-right: 20px;
                            }

                            &__oldprice {
                                font-size: 28.22px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 125%;
                                color: $special-gray;
                                text-decoration: line-through;
                                margin-right: 40px;
                            }

                            &__wb {

                                flex: 1;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 125%;
                                margin-right: 24px;
                                display: inline-block;
                                color: $white !important;
                                padding: 20px;
                                text-decoration: none;
                                border-radius: 5px;
                                background: linear-gradient(108deg, #B82CA5 0%, #451671 100%);
                                text-align: center;

                                &:hover {
                                    background: linear-gradient(108deg, #B82CA5 0%, #451671 75%);
                                }

                            }

                            &__favorite {

                                cursor: pointer;
                                width: 32px;
                                height: 32px;
                                background: url('../img/sprite.svg#sprite-catalog-item-favourites-view') 0 0 no-repeat;

                                &:hover {
                                    background: url('../img/sprite.svg#sprite-catalog-item-favourites-hover-view') 0 0 no-repeat;
                                }

                                &.--active,
                                &.--active:hover {
                                    background: url('../img/sprite.svg#sprite-catalog-item-favourites-active-view') 0 0 no-repeat;
                                }

                            }

                        }

                    }

                }

            }

        }

        &__description {

            &-title {
                color: $black;
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: 153.846%;
                margin-bottom: 20px;
            }

            &-text {
                color: #2B2B2B;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 40px;
            }

        }

        &__properties {

            margin-bottom: 65px;

            &-list {

                /*display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: flex-start;*/
                display: grid;
                grid: {
                    template-columns: 45% 55%;
                }

                & .properties-list {

                    &__column {

                        &:nth-child(odd) {
                            padding-right: 10px;
                        }

                        &:nth-child(even) {
                            padding-left: 10px;
                        }

                    }

                    &__item {

                        display: flex;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        justify-content: flex-start;
                        margin-bottom: 20px;

                        &-name {

                            flex: 0 0 50%;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding-right: 12px;

                            & span {
                                color: #9B9B9B;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 125%;
                                flex: 0 0 auto;
                                padding-right: 11px;
                            }

                            &:after {
                                content: '';
                                display: block;
                                height: 0;
                                border-bottom: dashed 1px #BCBCBC;
                                flex: 1;
                                position: relative;
                                top: 1px;
                            }

                        }

                        &-value {
                            flex: 0 0 50%;
                            color: $special-brown-light;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 125%;
                        }

                    }

                }

            }

        }

        &__reviews {

            &-title {

                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 21px;

                & span.count {
                    display: inline-block;
                    color: $black;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 153.846%;
                    margin-right: 25px;
                }

                & span.add-review-btn {

                    padding: 11px 44px;
                    border-radius: 5px;
                    color: $white;
                    text-decoration: none;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    background-color: $ke-button-standart-color;
                    transition: background-color .25s linear;
                    text-align: center;
                    cursor: pointer;

                    &:hover {
                        background-color: $ke-button-standart-color-hover;
                        color: $white;
                    }

                    &:active,
                    &:focus {
                        background-color: $ke-button-standart-color-active;
                        color: $white;
                    }
                }

            }

            &-empty {
                color: #2B2B2B;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
                margin-right: 15px;
                text-align: center;
            }

            &-list {

                & .reviews-list {

                    &__item {

                        padding: 30px;
                        border-radius: 10px;
                        background: #F4F7FA;
                        margin-bottom: 13px;

                        &-title {

                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: flex-start;
                            margin-bottom: 14px;

                            & .item-title {

                                &__name {
                                    color: #2B2B2B;
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 150%;
                                    margin-right: 15px;
                                }

                                &__date {
                                    color: #949494;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 187.5%;
                                }

                            }

                        }

                        &-rating {

                            margin-bottom: 14px;

                            & .item-rating {

                                &__stars {
            
                                    display: flex;
                                    flex-wrap: wrap;
                                    align-items: flex-start;
                                    justify-content: flex-start;
                                    margin-right: 15px;
            
                                    &-item {            
            
                                        width: 23px;
                                        height: 20px;                            
                                        background: url('../img/sprite.svg#sprite-review-star-empty-view') 0 0 no-repeat;
                                        margin-right: 4px;
            
                                        &:last-child {
                                            margin-right: 0;
                                        }
            
                                        &.--half {
                                            background: url('../img/sprite.svg#sprite-review-star-half-view') 0 0 no-repeat;
                                        }
            
                                        &.--full {
                                            background: url('../img/sprite.svg#sprite-review-star-full-view') 0 0 no-repeat;
                                        }
            
                                    }
            
                                }


                            }

                        }

                        &-text {

                            & .item-text {

                                &__wrap {

                                    max-height: 140px;
                                    overflow: hidden;

                                    &.--full-text {
                                        max-height: inherit;
                                        overflow: visible;
                                    }

                                }

                                &__review {
                                    color: #2B2B2B;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 175%;
                                }

                                &__show-all {

                                    display: none;
                                    margin-top: 14px;
                                    color: #336298;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 125%;
                                    cursor: pointer;

                                    &:hover {   
                                        opacity: 0.7;
                                    }

                                    &.--active {
                                        display: inline-block;
                                    }

                                }

                            }

                        }

                    }

                }

            }

        }

    }

}

@media screen and (max-width: 1499px) {

    .catalog {

        &__container {
    
            &-content {
                padding-left: 23px;
            }
    
        }

    }

}

@media screen and (max-width: 1439px) {

    .catalog {

        &-product {
    
            &__content {
                padding-left: 110px;
            }
    
            &__picture {

                flex: 0 1 410px;
    
                & .product-picture {
    
                    &__slides {    
                        flex: 0 0 90px;
                        width: 90px;    
                    }
    
                    &__photo {

                        &-list {
    
                            width: 300px;

                            & .photo-list {

                                &__item {
    
                                    & picture,
                                    & img {
                                        max-width: 300px;
                                    }

                                }

                            }
    
                        }
    
                    }
    
                }
    
            }
            
            
        }

    }

}

@media screen and (max-width: 1169px) {

    .catalog {

        &__products {
    
            &-list {
    
                & .products-list {
    
                    &__item {
                        flex: 0 0 312px;
                        max-width: 312px;
                    }
    
                }
            }
    
        }

        &-product {

            &__picture {
    
                & .product-picture {
    
                    &__photo {
    
                        &-add-to-favourite {
                            display: flex;
                        }

                    }

                }

            }

            &__info {

                &-offers {

                    & .info-offers {

                        &__blocks {
    
                            padding-bottom: 10px;
                            border-bottom: solid 1px $special-gray;
                            margin-bottom: 30px;
    
                            &-item {
    
                                & .blocks-item {
    
                                    &__list {

                                        margin-bottom: 12px;
    
                                        &-offer {
    
                                            margin: 0 0 8px 0;
                                            width: calc(50% - 9px);
                                            border-color: #D6D6D6;

                                            &:nth-child(odd) {
                                                margin-right: 9px;
                                            }

                                            &:nth-child(even) {
                                                margin-left: 9px;
                                            }

                                            &.--full {

                                                width: 100%;
                                                margin: 0 0 8px 0;

                                                &:nth-child(odd),
                                                &:nth-child(even) {
                                                    margin: 0 0 8px 0;
                                                }

                                            }
    
                                        }
    
                                    }
    
                                }
    
                            }
    
                        }
    
                        &__buy-block {
    
                            justify-content: flex-end;
    
                            & .buy-block {

                                &__oldprice {
                                    margin-right: 0;
                                }
    
                                &__wb {
                                    display: block;
                                    flex: 0 0 100%;
                                    width: 100%;  
                                    margin-top: 30px;  
                                    margin-right: 0;
                                }
    
                                &__favorite {    
                                    display: none;    
                                }
    
                            }
    
                        }
    
                    }

                }

            }
            
        }

    }

}

@media screen and (max-width: 991px) {

    .catalog {

        &__container {
    
            &-sidebar {    
                display: none;  
            }
    
            &-content {
                flex: 0 0 100%;
                padding-left: 0;
            }
    
        }

        &__products {

            &-filters {

                position: relative;
                margin-top: -10px;
                padding-bottom: 20px;
                border-bottom: $light-blue solid 1px;
    
                & .products-filters {
        
                    &__filter {
    
                        display: flex;
                    }

                }

            }
    
            &-list {
    
                & .products-list {
    
                    &__item {
                        flex: 0 0 222px;
                        max-width: 222px;
                    }
    
                }

            }

        }

        &-product {
    
            &__content {
                padding-left: 0px;
            }

            &__picture {

                flex: 0 1 664px;
                margin-bottom: 60px;
    
                & .product-picture {
    
                    &__photo {
    
                        &__slides {
                            flex: 0 0 120px;
                            width: 120px;   
                        }

                        &-list {
    
                            width: 524px;

                            & .photo-list {

                                &__item {
    
                                    & picture,
                                    & img {
                                        max-width: 524px;
                                    }

                                }

                            }
    
                        }
    
                        &-add-to-favourite {
                            display: none;
                        }

                    }

                }

            }

            &__info {

                padding-left: 0;

                &-offers {

                    & .info-offers {

                        &__blocks {
    
                            padding-bottom: 10px;
                            border-bottom: solid 1px $special-gray;
                            margin-bottom: 30px;
    
                            &-item {
    
                                & .blocks-item {
    
                                    &__list {

                                        margin-bottom: 12px;
    
                                        &-offer {
    
                                            margin: 0 17px 20px 0;
                                            width: 190px;
                                            border-color: #383E45;

                                            &:nth-child(odd) {
                                                margin-right: 17px;
                                            }

                                            &:nth-child(even) {
                                                margin-left: 0px;
                                            }

                                            &.--full {

                                                width: 190px;
                                                margin: 0 17px 20px 0;

                                                &:nth-child(odd),
                                                &:nth-child(even) {
                                                    margin: 0 17px 20px 0;
                                                }

                                            }
    
                                        }
    
                                    }
    
                                }
    
                            }
    
                        }
    
                        &__buy-block {
    
                            justify-content: flex-start;
    
                            & .buy-block {

                                &__oldprice {
                                    margin-right: 40px;
                                }
    
                                &__wb {

                                    display: inline-block;
                                    flex: 1;
                                    width: auto;  
                                    margin-top: 0px;  
                                    margin-right: 24px;
                                }
    
                                &__favorite {    
                                    display: inline-block;    
                                }
    
                            }
    
                        }
    
                    }

                }

            }
            
        }        

        &.--open-catalog-filter {

            & .catalog__filter.form {
                width: 100%;
                position: relative;
            }

            & .catalog__container-content {
                display: none;
            }

            & .catalog__container-sidebar {
                display: flex;
                flex: 0 0 100%;
                width: 100%;
            }

            & .catalog__filter-title {
                display: flex;
            }

        }

    }

}

@media screen and (max-width: 767px) {

    .catalog {

        &__products {

            &-list {
    
                & .products-list {
    
                    &__item {
                        flex: 0 0 252px;
                        max-width: 252px;
                    }
    
                }

            }

        }

        &-product {
    
            &__picture {
    
                flex-wrap: wrap;
                flex: 1 1 100%;
                width: 100%;
    
                & .product-picture {
    
                    &__slides {
    
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-around;
                        flex: 1 100%;
                        width: 100%;
                        order: 1;
    
                        &-item {    
                            margin-bottom: 0px;
                            flex: 1;
                            height: auto;

                            & img {
                                border-radius: 10px;
                                margin: 0 auto;
                            }
                        }

    
                    }
    
                    &__photo {
    
                        padding-left: 0px;
                        margin-bottom: 26px;
                        order: 0;
                        max-width: 100%;

                        &-list {
    
                            width: inherit;

                            & .photo-list {

                                &__item {
    
                                    & picture,
                                    & img {
                                        max-width: 100%;
                                    }

                                }

                            }
    
                        }
    
                        &-add-to-favourite {    
                            display: flex;    
                        }
    
                    }
    
                }
    
            }

            &__info {

                flex: 1;
                width: 100%;

                &-offers {

                    & .info-offers {

                        &__blocks {
    
                            padding-bottom: 10px;
                            border-bottom: solid 1px $special-gray;
                            margin-bottom: 30px;
    
                            &-item {
    
                                & .blocks-item {
    
                                    &__list {

                                        margin-bottom: 12px;
    
                                        &-offer {
    
                                            margin: 0 0 8px 0;
                                            width: calc(50% - 9px);
                                            border-color: #D6D6D6;

                                            &:nth-child(odd) {
                                                margin-right: 9px;
                                            }

                                            &:nth-child(even) {
                                                margin-left: 9px;
                                            }

                                            &.--full {

                                                width: 100%;
                                                margin: 0 0 8px 0;

                                                &:nth-child(odd),
                                                &:nth-child(even) {
                                                    margin: 0 0 8px 0;
                                                }

                                            }
    
                                        }
    
                                    }
    
                                }
    
                            }
    
                        }
    
                        &__buy-block {
    
                            justify-content: flex-end;
    
                            & .buy-block {

                                &__oldprice {
                                    margin-right: 0;
                                }
    
                                &__wb {
                                    display: block;
                                    flex: 0 0 100%;
                                    width: 100%;  
                                    margin-top: 30px;  
                                    margin-right: 0;
                                }
    
                                &__favorite {    
                                    display: none;    
                                }
    
                            }
    
                        }
    
                    }

                }

            }

            &__properties {
    
                &-list {
    
                    grid: {
                        template-columns: 1fr;
                    }

                    & .properties-list {
    
                        &__column {
    
                            &:nth-child(odd) {
                                padding-right: 0px;
                            }
    
                            &:nth-child(even) {
                                padding-left: 0px;
                            }
    
                        }

                    }

                }

            }
            
        }
        
    }

}

@media screen and (max-width: 575px) {

    .catalog {

        &-product {

            &__picture {
    
                & .product-picture {
    
                    &__slides {
    
                        &-item {    

                            & img {
                                height: 140px;
                                width: auto;
                            }
                        }
    
                    }

                }

            }

            &__info {                

                &-rating {

                    & .info-rating {

                        &__stars {

                            margin-right: 10px;

                            &-item {            

                                margin-right: 4px;

                                &:last-child {
                                    margin-right: 0;
                                }

                            }

                        }

                        &__count {
                            margin-right: 15px;
                        }

                    }

                }

            }

            &__reviews {
    
                &-title {
    
                    & span.count {
                        margin-right: 15px;
                    }
    
                    & span.add-review-btn {
                        padding: 11px 14px;
                        margin-left: auto;
                    }
    
                }
    
            }
            
        }

    }

}