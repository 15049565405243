.about {

    &__heading {
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: $standart-text-color;
        margin-bottom: 40px;
    }

    &__separator {
        margin: 40px 0;
        width: 210px;
        height: 6px;
        background-color: $special-brown;
    }

    &__media {

        border-radius: 10px;
        overflow: hidden;

        & > picture,
        & img {
            display: block;
            width: 100%;
            height: auto;
        }

        &.--why {

            height: 336px;
            margin-bottom: 125px;

            & .about__media-content {
                padding: 25px;
                color: $white;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 150;
            }

        }

        &-picture {

            position: relative;
            width: 100%;
            height: 100%;
            z-index: 110;

            & picture,
            & img {
                display: block;
                position: absolute;
                width: 100%;
                height: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

        }

        &-left,
        &-right {

            flex: 0 0 50%;
            position: relative;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 110;
            }

        }

        &-left:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            opacity: 0.45;
            background: $black;
            z-index: 120;
        }

        &-right:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            opacity: 0.35;
            background: $special-brown;
            z-index: 120;
        }

    }

    & p {
        color: $standart-text-color;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 30px;

        &.--mb-125 {
            margin-bottom: 125px;
        }

    }

    &__why-list {

        display: grid;
        margin-bottom: 125px;
        grid: {
            template-columns: 1fr 1fr 1fr;
            gap: 0 40px;
        }

        & .why-list {

            &__item {

                &-icon {

                    height: 101px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 40px;

                    &.--books:before {
                        content: '';
                        display: inline-block;
                        width: 93px;
                        height: 101px;
                        background: url('../img/sprite.svg#sprite-about-books-view') 0 0 no-repeat;
                        background-size: 100% 100%;
                    }

                    &.--clean:before {
                        content: '';
                        display: inline-block;
                        width: 100px;
                        height: 101px;
                        background: url('../img/sprite.svg#sprite-about-clean-view') 0 0 no-repeat;
                        background-size: 100% 100%;
                    }

                    &.--photo:before {
                        content: '';
                        display: inline-block;
                        width: 90px;
                        height: 64px;
                        background: url('../img/sprite.svg#sprite-about-photo-view') 0 0 no-repeat;
                        background-size: 100% 100%;
                    }

                }

                &-title {
                    color: $standart-text-color;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 40px;
                }

                &-text {
                    color: $standart-text-color;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }

            }

        }

    }

    &__deffect {
        margin: 125px auto;
        background-color: $light-blue;
        padding: 18px 30px;
        max-width: 1006px;
        color: $standart-text-color;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }

    &__content-creator {

        margin-top: 125px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        & .content-creator {

            &__title {
                flex: 1;
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-right: 40px;
                white-space: nowrap;
            }

            &__separator {
                flex: 1;
                width: 210px;
                height: 6px;
                background-color: $special-brown;
                margin-right: 40px;
            }

            &__youtube,
            &__telegram {

                flex: 1;
                display: inline-flex;
                margin-right: 40px;
                align-items: center;
                justify-content: center;
                padding: 13px 0;
                max-width: 307px;
                border-radius: 5px;
                border: 1px solid $light-blue;
                text-decoration: none;
                color: #555555;

                &:before {
                    content: '';
                    display: inline-block;
                    width: 32px;
                    height: 32px;
                    background: url('../img/sprite.svg#sprite-youtube-view') 0 0 no-repeat;
                    background-size: 100% 100%;
                    margin-right: 10px;
                }

                & span {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                }

                &:hover {
                    background-color: $light-blue;
                }

            }

            &__telegram {
                
                margin-right: 0px;

                &:before {
                    background: url('../img/sprite.svg#sprite-telegram-view') 0 0 no-repeat;
                    background-size: 100% 100%;
                }

            }

        }

    }

}

@media screen and (max-width: 1440px) {

    .about {

        &__media {
    
            &.--why {
    
                height: 276px;
    
            }

        }

    }

}

@media screen and (max-width: 1170px) {

    .about {

        &__media {
    
            &.--why {    
                height: 232px;    
            }

        }

        &__why-list {
    
            grid: {
                gap: 0 18px;
            }

        }

    }

}

@media screen and (max-width: 992px) {

    .about {

        &__media {
    
            &.--why {    
                height: auto;    
            }

            &-left,
            &-right {    
                flex: 0 0 100%;
                overflow: hidden;    
            }

            &-picture {    
                height: 336px;    
            }

        }

        &__why-list {
    
            grid: {
                template-columns: 1fr;
                gap: 25px 0px;
            }
    
        }

        &__deffect {
            max-width: 100%;
        }

        &__content-creator {
    
            margin-top: 125px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
    
            & .content-creator {
    
                &__title {
                    flex: 0 0 100%;
                }
    
                &__separator {
                    flex: 0 0 100%;
                    width: 100%;
                    margin: 40px 0;
                }
    
                &__youtube,
                &__telegram {    
                    flex: 0 0 100%;
                    margin-right: 0px;
                    max-width: 100%;
                }
    
                &__telegram {                    
                    margin-top: 40px;    
                }
    
            }
    
        }

    }

}

@media screen and (max-width: 768px) {

    .about {

        &__media {

            &-picture {    
                
                & picture,
                & img {
                    width: auto;
                }
                
            }

        }
        
    }

}

@media screen and (max-width: 576px) {

    .about {

        &__content-creator {
    
            & .content-creator {
    
                &__title {
                    white-space: break-spaces;
                }

            }

        }

    }

}