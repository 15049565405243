.novelty {

    &__filter {

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 20px;

    }

    &__list {

        margin-bottom: 0px;

        & .products-list__item {
            margin-bottom: 40px;
        }

    }


}

@media screen and (max-width: 1439px) {

    .novelty {

    }

}

@media screen and (max-width: 1169px) {

    .novelty {
        
    }

}

@media screen and (max-width: 991px) {

    .novelty {

    }

}

@media screen and (max-width: 767px) {

    .novelty {       
        
        & .sortable__select-list {
            right: auto;
            left: 0;
        }

        &__filter {
            justify-content: center;
            padding-bottom: 30px;
            border-bottom: solid 1px $light-blue;
        }
        
    }

}

@media screen and (max-width: 575px) {

    .novelty {

    }

}