.collections {

    &-list {

        &__item {

            &-heading {

                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 22px;

                & .item-heading {

                    &__title {
                        flex: 1;
                        font-size: 26px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        color: $standart-text-color-2;
                    }

                    &__count {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        color: $special-brown-light;
                        white-space: nowrap;
                        margin-left: auto;
                        margin-right: 40px;
                        margin-left: 40px;
                    }

                    &__show {

                        display: inline-block;
                        white-space: nowrap; 
                        padding: 12px;
                        border-radius: 5px;
                        color: $white;
                        text-decoration: none;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        background-color: $ke-button-standart-color;
                        transition: background-color .25s linear;
                        text-align: center;
                        width: 270px;

                        &:hover {
                            background-color: $ke-button-standart-color-hover;
                            color: $white;
                        }

                        &:active,
                        &:focus {
                            background-color: $ke-button-standart-color-active;
                            color: $white;
                        }

                    }

                }

            }

            &-content {

                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: flex-start;
                margin-bottom: 60px;

                & .item-content {

                    &__description {

                        flex: 0 0 524px;

                        &-picture {
                            
                            width: 100%;
                            border-radius: 10px;
                            overflow: hidden;
                            margin-bottom: 20px;
    
                            & picture,
                            & img {
                                display: block;
                                width: 100%;
                                height: auto;
                            }
    
                        }

                        &-text {
                            color: #9B9B9B;
                            font-family: Nunito;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 125%;
                        }

                    }

                    &__products {

                        flex: 1;
                        padding-left: 20px;

                        &-list {

                        }

                    }

                }
                

            }

        }

    }

    &-detail {

        &__picture {

            border-radius: 10px;
            overflow: hidden;

            & picture,
            & img {
                display: block;
                width: 100%;
                height: auto;
            }

        }

        &__separator {
            width: 210px;
            height: 6px;
            background-color: $special-brown;
            margin: 40px 0;
        }

        &__description {

            color: #222222;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 60px;

            & p {

                color: #222222;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 40px;

                &:last-child {
                    margin-bottom: 0;
                }

            }

        }

        &__title {
            color: $standart-text-color;
            font-size: 34px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 30px;
            padding-bottom: 10px;
            border-bottom: solid 1px $light-blue;
        }

    }

}

@media screen and (max-width: 1439px) {

    .collections {

        &-list {
    
            &__item {
    
                &-content {
    
                    & .item-content {
    
                        &__products {
    
                            &-list {

                                & .products-list__item:nth-child(3) {
                                    display: none;
                                }
    
                            }
    
                        }

                    }

                }

            }

        }

    }

}

@media screen and (max-width: 1169px) {

    .collections {

        &-list {
    
            &__item {
    
                &-content {
    
                    & .item-content {
    
                        &__products {
    
                            &-list {

                                justify-content: flex-end;

                                & .products-list__item:nth-child(2) {
                                    display: none;
                                }
    
                            }
    
                        }

                    }

                }

            }

        }
        
    }

}

@media screen and (max-width: 991px) {

    .collections {

        &-list {
    
            &__item {
    
                &-content {                    

                    & .item-content {

                        &__description {
                            flex: 0 0 415px;
                        }
    
                        &__products {
    
                            &-list {

                                & .products-list__item:hover {
                                    height: 408px;
                                }
    
                            }
    
                        }

                    }

                }

            }

        }

    }

}

@media screen and (max-width: 767px) {

    .collections {

        &-list {
    
            &__item {                

                &-heading {

                    & .item-heading {

                        &__count {
                            display: none;
                        }

                        &__show {

                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            padding: 0;
                            border-radius: 0px;
                            color: $special-brown;
                            font-size: 20px;
                            font-weight: 400;
                            background-color: transparent;
                            transition: none;
                            text-align: left;
                            width: auto;

                            & > span {
                                display: inline-block;
                                margin-right: 15px;
                            }

                            &-arrow {

                                display: flex !important;
                                align-items: center;
                                justify-content: center;
                                width: 50px;
                                height: 50px;
                                background-color: $special-brown;
                                border-radius: 5px;
                                margin-right: 0 !important;

                                &:after {
                                    content: '';
                                    display: inline-block;
                                    width: 12px;
                                    height: 20px;
                                    background: url('../img/sprite.svg#sprite-btn-white-arrow-right-view') 0 0 no-repeat;
                                }

                            }

                            &:hover {

                                background-color: transparent;
                                color: $special-brown-light;

                                & .item-heading__show-arrow {
                                    background-color: $special-brown-light;    
                                }

                            }

                            &:active,
                            &:focus {
                                background-color: transparent;
                                color: $special-brown-light;
                            }

                        }

                    }

                }
    
                &-content {
    
                    & .item-content {

                        &__description {
                            flex: 0 0 100%;
                        }
    
                        &__products {
                            display: none;    
                        }

                    }

                }

            }

        }
        
    }

}

@media screen and (max-width: 575px) {

    .collections {

    }

}