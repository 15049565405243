.popular {

    &__list {

        margin-bottom: 0px;

        & .products-list__item {
            margin-bottom: 40px;
        }

    }

}

@media screen and (max-width: 1439px) {

    .popular {

    }

}

@media screen and (max-width: 1169px) {

    .popular {
        
    }

}

@media screen and (max-width: 991px) {

    .popular {

    }

}

@media screen and (max-width: 767px) {

    .popular {
        
    }

}

@media screen and (max-width: 575px) {

    .popular {

    }

}