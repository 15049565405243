.search {    

    &__title {

        margin-bottom: 21px;
		border-bottom: $light-blue solid 1px;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        &-text {

            color: $black;
            font-size: 34px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 0 !important;
            border-bottom: none !important;
            display: inline-block;

            &:before {
                content: '';
                display: inline-block;
                width: 46px;
                height: 46px;
                background: url('../img/sprite.svg#sprite-zoom-search-dark-view') no-repeat;
                margin-right: 8px;
                position: relative;
                top: 11px;
            }

            &:after {
                content: attr(data-total);
                display: inline-block;
                color: #9B9B9B;
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding-left: 20px;
            }

        }

    }


}

@media screen and (max-width: 1439px) {

    .search {

    }

}

@media screen and (max-width: 1169px) {

    .search {
        
    }

}

@media screen and (max-width: 991px) {

    .search {

    }

}

@media screen and (max-width: 767px) {

    .search {       
    }

}

@media screen and (max-width: 575px) {

    .search {

        &__title {
    
            &-text {
    
                &:after {
                    display: none;
                }
    
            }
    
        }

    }

}